import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'
import { BodyLargeBold, BodySmall } from 'components/base/typography'
import { EndSession } from 'components/libs/SessionDetails/style'
import Loader from 'components/libs/Loader'

interface Props {
  closeModal: () => void
  handleCompleteSession: (appointmentID: number, answer: boolean) => void
  appointmentID: number
  loading: boolean
}

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-right: auto;
  margin-top: 60px;
  font-family: 'Plus Jakarta Sans';
`

const Info = styled.div`
  text-align: center;
  margin-top: 40px;
  color: #344054;
`
const CompleteSession: React.FC<Props> = ({
  closeModal,
  handleCompleteSession,
  appointmentID,
  loading,
}) => {
  return (
    <div data-testId={'therapist-log-out'}>
      <Info>
        {loading ? (
          <BodyLargeBold marginBottom={'20px'}>
            Ending session... please wait...
          </BodyLargeBold>
        ) : (
          <>
            <BodyLargeBold
              style={{
                marginBottom: 15,
              }}
            >
              Did the client show up to the session?
            </BodyLargeBold>
            <BodySmall>
              We'll use your response to verify our attendance records.
              Providing a false answer here may incur penalties on your
              acccount.
            </BodySmall>
          </>
        )}
      </Info>
      {loading ? (
        <Loader width={'50px'} height={'50px'} color={'#344054'} size={'1'} />
      ) : (
        <Action>
          <EndSession
            type={'button'}
            onClick={() => handleCompleteSession(appointmentID, true)}
          >
            No, they didn't 😔
          </EndSession>
          <Button
            variant={ButtonState.SECONDARY}
            padding={'4px 16px'}
            type={'button'}
            onClick={() => handleCompleteSession(appointmentID, false)}
            width={'100%'}
            border_radius="8px"
          >
            Yes, they did 😊
          </Button>
        </Action>
      )}
    </div>
  )
}

export default CompleteSession

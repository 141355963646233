import styled from 'styled-components'
import UnstyledButton from 'components/libs/UnstyledButton'
import { device } from 'helpers/breakpoints'
import { BodyNormal } from 'components/base/typography'
import { Phone, Video } from 'assets/svg'
import { useDispatch } from 'react-redux'
import { Bars } from 'assets/svg'
import { toggleMobileMenu } from 'store/modules/modals/actions'
import { appointmentService } from 'services'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { errorHandler } from 'helpers'

interface WrapperProps {
  show: boolean
}
const Wrapper = styled.header<WrapperProps>`
  height: 80px;
  position: sticky;
  top: 0;
  width: ${(p) => (p.show ? 'calc(100% - 380px)' : '100%')};
  padding: 15px 28px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  /* margin-bottom: 25px; */

  @media ${device.tablet} {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  & button {
    display: none;

    @media ${device.tablet} {
      display: block;
    }
  }
`

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    & path {
      fill: #1d2939;
    }
  }

  & span {
    margin-right: 20px;

    & svg {
      width: 25px;
      height: 25px;
    }
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  margin-left: auto;
`
const Image = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`

interface HeaderProps {
  image: string
  name: string
  showDetails: boolean
  handleCloseSession: (appointmentID: number) => void
  handleCall: (type: 'voice' | 'video') => void
  id: number
}
const Header: React.FC<HeaderProps> = ({
  image,
  name,
  showDetails,
  handleCall,
  id,
}) => {
  const dispatch = useDispatch()
  const { data: account } = useFetchAccount()

  const therapistName = account?.name

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }

  const handleCallType = async (type: 'voice' | 'video') => {
    handleCall(type)

    // SEND TRIGGER NOTIFICATION TO THE SERVER
    const message = {
      id,
      message: `${therapistName} has joined the ${type} call room`,
    }

    try {
      await appointmentService.sendMessage(message)
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <Wrapper show={showDetails}>
      <UnstyledButton onClick={handleToggle}>
        <Bars width={24} height={25} color="#667085" />
      </UnstyledButton>

      <ChatHeader>
        {/* <span onClick={() => handleCloseSession()}>
            <ArrowLeft />
          </span> */}
        <Image>
          <img src={image} alt={name} />
        </Image>
        <BodyNormal color={'#344054'} fontWeight={600}>
          {name}
        </BodyNormal>
        <Action>
          <Video onClick={() => handleCallType('video')} />
          <Phone onClick={() => handleCallType('voice')} />
        </Action>
      </ChatHeader>
    </Wrapper>
  )
}

export default Header

import { DataAction } from '../../types'
import { reducerMultiplexer } from '../../utils'
import {
  TOGGLE_MOBILE_MENU,
  TOGGLE_SESSION_DETAILS,
  modalState,
  ModalStateProps,
} from './types'

export default function modalsReducer(
  modalsState: ModalStateProps = modalState,
  modalsAction: DataAction
) {
  return reducerMultiplexer<ModalStateProps>({
    [TOGGLE_MOBILE_MENU]: (state, action) => {
      return {
        ...state,
        show_sidebar: !state.show_sidebar,
      }
    },
    [TOGGLE_SESSION_DETAILS]: (state, action) => {
      return {
        ...state,
        show_session_details: !state.show_session_details,
      }
    },
  })(modalsState, modalsAction)
}

import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

export const fetcher = () => {
  const response = fetchDataService.appointmentRequest()

  return response
}

const useFetchAppointmentRequest = () => {
  return useQuery(['appointment-request'], fetcher)
}

export default useFetchAppointmentRequest

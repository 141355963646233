import { HttpMethod } from './HttpService'
import QueryService from './QueryService'

export interface ChangePasswordProps {
  current_password: string
  password: string
  password_confirmation: string
}

export interface ResetPasswordProps {
  email: string
}

export interface UpdateBankProps {
  account_name: string
  account_number: string
  bank_code: string
  bank_name: string
}

export interface UpdateSessionProps {
  student_plan: boolean
  student_price?: number
  accepts_couples: boolean
  couples_price?: number
  price: number
  same_day_booking: boolean
  discount: boolean
  discount_limit?: number
  days: string[]
  exceptions: string[]
  time: {
    from: string
    to: string
  }
  availability_for_days: any
}

export interface UpdateProfileProps {
  bio: string
  facebook_url: string
  instagram_url: string
  linked_url: string
  twitter_url: string
  role: string
  specialized_in: string
  years_of_experience: string
  qualifications: string
}

export interface UpdateAvatarProps {
  photo: any
}

export interface WithdrawalProps {
  amount: number
}

export interface SubscribeToNotificationsProps {
  type: string
  device: string
  value: any
}

export interface AwayProps {
  away_from: string
  away_until: string
}

export default class AccountService {
  constructor(private queryService: QueryService) {}

  async changePassword(data: ChangePasswordProps) {
    const response = await this.queryService.fireRequest(
      {
        url: 'change_password',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return response
  }

  async updateBankDetails(data: UpdateBankProps) {
    const response = await this.queryService.fireRequest(
      {
        url: 'account/bank',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return response
  }

  async updateSessionSettings(data: UpdateSessionProps) {
    const reponse = await this.queryService.fireRequest(
      {
        url: 'account/session',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return reponse
  }

  async updateProfile(data: UpdateProfileProps) {
    const response = await this.queryService.fireRequest(
      {
        url: 'account',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return response
  }

  async updateAvatar(data: UpdateAvatarProps) {
    const body = new FormData()
    body.append('photo', data.photo)
    const response = await this.queryService.fireRequest(
      {
        url: 'account/avatar',
        method: HttpMethod.POST,
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      true
    )

    return response
  }

  async resetPassword(data: ResetPasswordProps) {
    const response = await this.queryService.fireRequest(
      {
        url: 'password/reset',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return response
  }

  async withdrawal(data: WithdrawalProps) {
    return await this.queryService.fireRequest(
      {
        url: 'withdrawals',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )
  }

  async subscribeToNotifications(data: SubscribeToNotificationsProps) {
    return await this.queryService.fireRequest(
      {
        url: 'onesignal',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )
  }

  async setAwayPeriod(data: AwayProps) {
    const response = await this.queryService.fireRequest(
      {
        url: 'account/away',
        method: HttpMethod.POST,
        body: data,
      },
      true
    )

    return response
  }

  async cancelAwayPeriod() {
    const response = await this.queryService.fireRequest(
      {
        url: 'account/away_cancel',
        method: HttpMethod.POST,
      },
      true
    )

    return response
  }
}

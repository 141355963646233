import React from 'react'
import { NoteStatusType } from '../helpers'
import {
  BodySmall,
  BodyNormalSemiBold,
  BodyNormal,
} from 'components/base/typography'
import { useDeleteNote } from 'hooks/useNotes'
import UnstyledButton from 'components/libs/UnstyledButton'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 16px 20px;
  border-bottom: 2px solid #eaecf0;
`

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18.56px;
`

interface DeleteProps {
  noteName: string
  handleCancel: (status: NoteStatusType) => void
  noteID: number
}
const DeleteNote: React.FC<DeleteProps> = ({
  noteName,
  handleCancel,
  noteID,
}) => {
  const { mutate } = useDeleteNote()

  const handleDeleteNote = () => {
    mutate(noteID)
    handleCancel('view')
  }

  return (
    <Wrapper>
      <BodyNormalSemiBold color={'#D12020'}>
        Delete {noteName}?
      </BodyNormalSemiBold>
      <BodySmall color="#5D6673">
        This action will permanently remove this note from your records.
      </BodySmall>

      <BtnWrapper>
        <UnstyledButton>
          <BodyNormal color={'#D12020'} onClick={handleDeleteNote}>
            Delete
          </BodyNormal>
        </UnstyledButton>
        <UnstyledButton onClick={() => handleCancel('view')}>
          <BodyNormal color="#5D6673">Cancel</BodyNormal>
        </UnstyledButton>
      </BtnWrapper>
    </Wrapper>
  )
}

export default DeleteNote

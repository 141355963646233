import { withTheme } from 'hoc/withTheme'
import { FC, ReactElement, ReactNode } from 'react'
import { Button as ButtonStyle, ButtonState } from './style'

export interface ButtonProps {
  variant: ButtonState
  padding?: string
  icon?: ReactElement
  children: ReactNode
  border_radius?: string
  type: 'button' | 'submit'

  [key: string]: any
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyle {...props} data-testid={'therapist-btn'}>
      {props.icon ? <>{props.icon}</> : ''}
      {props.children}
    </ButtonStyle>
  )
}

export default withTheme<ButtonProps>(Button)

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { appointmentService } from 'services'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { TherapistToast } from 'helpers'
import SessionNote from 'components/libs/SessionNote'
import SessionChat from 'components/libs/SessionChat'
import { useParams } from 'react-router-dom'
import Header from './Header'
import useFetchAppointment from 'hooks/appointments/useFetchAppointment'
import Loader from 'components/libs/Loader'
import { useDispatch } from 'react-redux'
import { toggleSessionDetails } from 'store/modules/modals/actions'
import { errorHandler, successHandler } from 'helpers'
import useCompleteAppointment from 'hooks/appointments/useCompleteAppointment'
import CompleteSession from 'components/libs/Modal/CompleteSession'
import Modal from 'components/libs/Modal'

const LoaderWrapper = styled.div`
  height: 70vh;
`

const Session = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [call, setCall] = useState<'video' | 'voice' | 'chat'>('chat')
  const [callBody, setCallBody] = useState({ token: '', channelName: '' })

  const { data, isLoading } = useFetchAppointment(id)
  const { data: account } = useFetchAccount()
  const { mutate, isLoading: isCompleting } = useCompleteAppointment()
  const [showCompleteSessionModal, setShowCompleteSessionModal] =
    useState(false)

  const handleToggleModal = () => {
    setShowCompleteSessionModal(!showCompleteSessionModal)
  }

  const therapistName = account?.name

  const handleEndSession = async (appointmentID: number, answer: boolean) => {
    const completeSessionData = {
      id: appointmentID,
      missed: answer,
    }

    const endSessionFunc = async () => {
      navigate(`/dashboard/bookings`)
      TherapistToast.success({ msg: 'Session completed successfully! 🎉' })
      // SEND TRIGGER NOTIFICATION TO THE SERVER
      await triggerNotification(`${therapistName} has left the call room`)
    }

    mutate(completeSessionData, {
      onSuccess(data) {
        if (data.message) {
          endSessionFunc()
        }
      },
    })
  }
  const handleCalls = async (type: 'video' | 'voice' | 'chat') => {
    setCall(type)

    await triggerNotification(
      `${therapistName} has joined the ${
        type === 'video' ? 'video' : 'voice'
      } room`
    )
  }

  const handleCloseModal = () => {
    dispatch(toggleSessionDetails())
  }

  const startSession = async () => {
    try {
      const responseData = await appointmentService.joinAppointmentCall(+id!)

      successHandler('Session Started')

      const callObject = {
        token: responseData.token,
        channelName: responseData.channel,
      }

      setCallBody(callObject)

      // SEND TRIGGER NOTIFICATION TO THE SERVER
      await triggerNotification(`${therapistName} has joined the call room`)
    } catch (e) {
      errorHandler(e)
      TherapistToast.error({ msg: 'An error occured, please contact support!' })
      navigate(`/dashboard/bookings`)
    }
  }

  const triggerNotification = async (triggerMessage: string) => {
    // SEND TRIGGER NOTIFICATION TO THE SERVER
    try {
      const message = {
        id: +id!,
        message: triggerMessage,
      }

      await appointmentService.sendMessage(message)
    } catch (e) {
      errorHandler(e)
    }
  }

  useEffect(() => {
    startSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading || !callBody.channelName || !callBody.token ? (
    <LoaderWrapper>
      <Loader width={'200px'} height={'200px'} color={'#344054'} size={'1'} />
    </LoaderWrapper>
  ) : (
    <>
      <Header
        name={`${data?.user.first_name} ${data?.user.last_name}`}
        id={data?.id}
        showDetails={true}
        image={data?.user.avatar}
        handleCloseSession={handleToggleModal}
        handleCall={handleCalls}
      />
      <SessionChat
        callType={call}
        userId={data?.user.id}
        chatToken={callBody.token}
        channelName={data?.channel_name}
        handleCallType={handleCalls}
        appointmentId={data?.id}
      />
      <SessionNote
        show={true}
        name={`${data?.user.first_name} ${data?.user.last_name}`}
        image={data?.user.avatar}
        time={data?.started_at}
        reason={data?.reason}
        appointmentID={data?.id}
        userID={data?.user.id}
        description={data?.note}
        type={data?.type}
        handleClose={handleCloseModal}
        handleEndSession={handleToggleModal}
      />

      {/* QUESTION TO COMPLETE SESSION */}
      <Modal
        show={showCompleteSessionModal}
        handleAction={handleToggleModal}
        width={'500px'}
      >
        <CompleteSession
          closeModal={handleToggleModal}
          handleCompleteSession={handleEndSession}
          appointmentID={data?.id}
          loading={isCompleting}
        />
      </Modal>
    </>
  )
}

export default Session

import React, { useState } from 'react'
import { NoteStatusType } from '../helpers'
import Loader from 'components/libs/Loader'
import { NoteWrapper } from '../../SessionNote/Notes'
import NewNoteForm from '../../SessionNote/utils/NewNoteForm'
import DeleteNote from '../../SessionNote/utils/DeleteNote'
import NoteItem from '../../SessionNote/utils/NoteItem'
import UpdateNoteForm from '../../SessionNote/utils/UpdateNoteForm'
import styled from 'styled-components'
import { getFilteredResults } from '../helpers'
import Heading from './Heading'

const Wrapper = styled.div`
  padding: 18px 31px;
`
const LoaderWrapper = styled.div`
  height: 20%;
`

interface ContentProps {
  data: any[]
  userID: number
  isLoading: boolean
}

const Content: React.FC<ContentProps> = ({ data, userID, isLoading }) => {
  const [noteStatus, setNoteStatus] = useState<NoteStatusType>('view')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState<any>([])

  const [noteDetails, setNoteDetails] = useState({
    id: 0,
    title: '',
    content: '',
  })

  const handleNoteStatus = (status: NoteStatusType) => {
    setNoteStatus(status)
  }

  const handleNoteDetails = (
    status: NoteStatusType,
    noteId: number,
    noteTitle: string,
    content: string
  ) => {
    setNoteDetails({ id: noteId, title: noteTitle, content: content })

    handleNoteStatus(status)
  }

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
    const filteredData = getFilteredResults(data, searchTerm)

    setSearchResult(filteredData)
  }

  const userNotes = searchTerm === '' ? data : searchResult

  return (
    <Wrapper style={{ height: '100%' }}>
      <Heading
        handleFilter={handleSearch}
        handleNoteStatus={handleNoteStatus}
      />

      {isLoading && (
        <LoaderWrapper>
          <Loader width={'70px'} height={'70px'} color={'#344054'} size={'2'} />
        </LoaderWrapper>
      )}
      {noteStatus === 'new' && (
        <NewNoteForm
          userID={userID}
          appointmentID={0}
          handleCancel={handleNoteStatus}
        />
      )}

      {noteStatus === 'delete' && (
        <DeleteNote
          noteName={noteDetails.title}
          noteID={noteDetails.id}
          handleCancel={handleNoteStatus}
        />
      )}

      {noteStatus === 'edit' && (
        <UpdateNoteForm
          title={noteDetails.title}
          noteID={noteDetails.id}
          content={noteDetails.content}
          handleCancel={handleNoteStatus}
        />
      )}

      {!isLoading && (
        <NoteWrapper>
          {data?.length !== 0 && (
            <>
              {userNotes?.map((note: any) => {
                return (
                  <NoteItem
                    title={note.title}
                    content={note.content}
                    noteID={note.id}
                    key={note.id}
                    time={note.updated_at}
                    handleAction={handleNoteDetails}
                    activeSession={false}
                  />
                )
              })}
            </>
          )}
        </NoteWrapper>
      )}
    </Wrapper>
  )
}

export default Content

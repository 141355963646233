import { device } from 'helpers/breakpoints'
import styled from 'styled-components'

export const AuthMain = styled.main`
  display: flex;
  flex-wrap: wrap;
`

export const DashboardLayoutStyles = styled.div`
  display: flex;
  background: #f2f4f7;

  & > aside {
    width: 17.5rem;
    top: 0;
    height: 100vh;
    @media ${device.laptop} {
      position: fixed !important;
    }
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
  }
`

export const DesktopNav = styled.aside`
  display: block;
  @media ${device.laptop} {
    display: none;
  }
`

import React from 'react'
import styled, { keyframes } from 'styled-components'
import * as ToolTip from '@radix-ui/react-tooltip'
import { BodySmall } from 'components/base/typography'

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const ToolTipContent = styled(ToolTip.Content)`
  border-radius: 8px;
  padding: 20px;
  font-size: 15px;
  line-height: 1;
  width: 250px;
  cursor: pointer;
  color: #3d4757;
  background-color: #f6fffa;
  border: 1px solid #8cd8b2;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  will-change: transform, opacity;

  &[data-state='instant-open'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
`

interface TooltipProps {
  content: string
  children: React.ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <ToolTip.Provider delayDuration={0}>
      <ToolTip.Root>
        <ToolTip.Trigger asChild>{children}</ToolTip.Trigger>
        <ToolTip.Portal>
          <ToolTipContent sideOffset={5} data-side="bottom" side="bottom">
            <BodySmall>{content}</BodySmall>
            <ToolTip.Arrow fill="#f6fffa" stroke="#8cd8b2" />
          </ToolTipContent>
        </ToolTip.Portal>
      </ToolTip.Root>
    </ToolTip.Provider>
  )
}

export default Tooltip

import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'
import { BodyNormal, SubHeadingBold } from 'components/base/typography'
import { SuccessIcon } from 'assets/svg'

interface Props {
  closeModal: () => void
  [x: string]: any
}

const Action = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 49px;
`

const IconArea = styled.div`
  display: flex;
  justify-content: center;
`

const PrescriptionCreationSuccess: React.FC<Props> = ({ closeModal }) => {
  return (
    <div data-testid={'therapist-withdrawal-success'}>
      <IconArea>
        <SuccessIcon />
      </IconArea>
      <SubHeadingBold textAlign={'center'} mt="32px">
        Prescription Saved Successfully
      </SubHeadingBold>

      <BodyNormal textAlign={'center'} width={'342px'} mx={'auto'} mt={'8px'}>
        You have added a prescription to this client’s profile. They will be
        notified and can now view their prescription.
      </BodyNormal>

      <Action>
        <Button
          variant={ButtonState.PRIMARY}
          padding={'16px 30px'}
          type={'button'}
          onClick={() => closeModal()}
          mb={'15px'}
        >
          Okay
        </Button>
      </Action>
    </div>
  )
}

export default PrescriptionCreationSuccess

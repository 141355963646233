interface TabProps {
  name: string
  tabType: 'about' | 'notes'
}

export type NoteStatusType = 'edit' | 'new' | 'delete' | 'view'

export const tabs: TabProps[] = [
  {
    name: 'About',
    tabType: 'about',
  },
  {
    name: 'Notes',
    tabType: 'notes',
  },
]

// GET USER AND THEIR NOTES

export const getUserDataAndNotes = (data: any[]) => {
  const userObjects = data
    ?.filter((item: any) => item.hasOwnProperty('user'))
    .map((item: any) => item.user)

  const userNotesMap = new Map()
  const userNotesArray: any = []

  userObjects?.forEach((user) => {
    const id = user?.id
    const name = `${user?.first_name} ${user?.last_name}`
    const avatar = user?.avatar

    if (userNotesMap.has(id)) {
      userNotesMap.set(id, {
        id,
        name,
        avatar,
        notes: userNotesMap.get(id).notes + 1,
      })
    } else {
      userNotesMap.set(id, {
        id,
        name,
        avatar,
        notes: 1,
      })
    }
  })

  userNotesMap.forEach((user) => {
    userNotesArray.push(user)
  })

  return userNotesArray
}

// Get Filtered result when a search occurs
export const getFilteredResults = (items: any[], filterKey: string) => {
  const filteredItems = items?.filter((item: any) =>
    Object.values(item)
      .join(' ')
      .toLowerCase()
      .includes(filterKey.toLowerCase())
  )

  return filteredItems
}

import React, { useEffect } from 'react'
import {
  SignUp,
  SignUpVerification,
  SignUpVerified,
  Qualification,
  Review,
  Login,
  ForgetPassword,
  ChangePassword,
  Dashboard,
  Bookings,
  ManageProfile,
  SessionSettings,
  WithdrawFunds,
  BankSettings,
  PasswordSettings,
  AwayPeriodSettings,
  Transactions,
  Notes,
  Session,
  Client,
} from './export'
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom'
import { DashboardLayout } from '../components/layouts'

function Redirect({ to }: { to: string }) {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}

interface RouteName {
  name:
    | 'manage-profile'
    | 'session-settings'
    | 'withdraw-funds'
    | 'bank-settings'
    | 'password-settings'
    | 'away-settings'
}

const RoutesContainer = () => {
  return (
    <React.Suspense fallback={<></>}>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup-verify" element={<SignUpVerification />} />
        <Route path="/signup-verified" element={<SignUpVerified />} />
        <Route path="/signup-qualification" element={<Qualification />} />
        <Route path={'/forget-password'} element={<ForgetPassword />} />
        <Route path="/review" element={<Review />} />
        <Route path="/login" element={<Login />} />
        <Route path={'/change-password'} element={<ChangePassword />} />
        <Route
          element={
            <DashboardLayout>
              <Outlet />
            </DashboardLayout>
          }
        >
          <Route path={'dashboard'} element={<Dashboard />} />
          <Route path={'dashboard/bookings'} element={<Bookings />} />
          <Route path={'dashboard/bookings/:id'} element={<Session />} />
          <Route path={'dashboard/client/:id'} element={<Client />} />
          <Route path={'dashboard/notes'} element={<Notes />} />
          <Route
            path={'dashboard/account/manage-profile'}
            element={<ManageProfile />}
          />
          <Route
            path={'dashboard/account/session-settings'}
            element={<SessionSettings />}
          />
          <Route
            path={'dashboard/account/away-settings'}
            element={<AwayPeriodSettings />}
          />
          <Route
            path={'dashboard/account/withdraw-funds'}
            element={<WithdrawFunds />}
          />
          <Route
            path={'dashboard/account/bank-settings'}
            element={<BankSettings />}
          />
          <Route
            path={'dashboard/account/password-settings'}
            element={<PasswordSettings />}
          />

          <Route path={'dashboard/transactions'} element={<Transactions />} />
        </Route>

        <Route path="/" element={<Redirect to="/signup" />} />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesContainer

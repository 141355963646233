import { TOGGLE_MOBILE_MENU, TOGGLE_SESSION_DETAILS } from './types'
import { DataAction } from '../../types'

export function toggleMobileMenu(): DataAction {
  return {
    type: TOGGLE_MOBILE_MENU,
  }
}

export function toggleSessionDetails(): DataAction {
  return {
    type: TOGGLE_SESSION_DETAILS,
  }
}

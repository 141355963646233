import styled from 'styled-components'
import { useState } from 'react'
import { BodySmallSemiBold, Paragraph } from 'components/base/typography'
import Modal from '../Modal'
import DeclineReason from 'components/libs/Modal/DeclineReason'
import ViewRequest from 'components/libs/Modal/ViewRequest'
import { device } from 'helpers/breakpoints'

const Wrapper = styled.div`
  border-bottom: 1px solid #eaecf0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 15px;

  @media ${device.mobileL} {
    gap: 0;
  }
`
export const Image = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`

export const Profile = styled.div`
  display: flex;
  flex-direction: column;

  ${Paragraph} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  @media ${device.mobileL} {
    margin-left: 15px;
  }
`
const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
`

export const Button = styled.button`
  width: 83px;
  height: 32px;
  padding: 0 16px;
  border-radius: 8px;
  text-align: center;
  height: 32px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: 12px;
  transition: all 0.2s;
`

export const Decline = styled(Button)`
  border: 1px solid #1b1d3e;
  color: #1b1d3e;
  background-color: #fff;

  &:hover {
    background-color: #1b1d3e;
    color: #fff;
  }
`
export const Accept = styled(Button)`
  background-color: #1b1d3e;
  border: 1px solid #1b1d3e;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #1b1d3e;
  }
`

export const View = styled(Button)`
  background-color: #1ab265;
  border: 1px solid #1ab265;
  color: #fff;
  /* padding: 0 10px; */
  width: fit-content;

  &:hover {
    background-color: #fff;
    color: #1ab265;
  }
`

interface ItemProps {
  id: number
  image: string
  name: string
  reason: string
  note: string
  date: string
}
const Item: React.FC<ItemProps> = ({ image, name, reason, id, note, date }) => {
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)

  const handleAcceptAppointment = () => {
    setShowAcceptModal(!showAcceptModal)
  }

  const handleRejectAppointment = () => {
    setShowDeclineModal(!showDeclineModal)
  }

  return (
    <Wrapper>
      <Image>
        <img src={image} loading="lazy" alt="person" />
      </Image>
      <Profile>
        <BodySmallSemiBold fontWeight={600} color={'#344054'}>
          {name}
        </BodySmallSemiBold>
        <Paragraph color={'#667085'}>Reason: {reason}</Paragraph>
      </Profile>
      <Action>
        <View type="button" onClick={handleAcceptAppointment}>
          View Request
        </View>
      </Action>

      {/* MODALS */}

      <Modal
        show={showDeclineModal}
        handleAction={handleRejectAppointment}
        width={'500px'}
      >
        <DeclineReason id={id} closeModal={handleRejectAppointment} />
      </Modal>
      <Modal
        show={showAcceptModal}
        handleAction={handleAcceptAppointment}
        width={'500px'}
      >
        <ViewRequest
          id={id}
          note={note}
          closeModal={handleAcceptAppointment}
          openRejectModal={handleRejectAppointment}
          image={image}
          reason={reason}
          name={name}
          date={date}
        />
      </Modal>
    </Wrapper>
  )
}

export default Item

import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { SubHeadingBold, BodySmall } from 'components/base/typography'
import Item from './Item'
import { ScrollBar } from 'styles/global'
import useFetchAppointmentRequest from 'hooks/appointments/useFetchAppointmentRequest'
import { Empty } from 'assets/svg'
import { Stack } from '@chakra-ui/react'

import Skeleton from 'react-loading-skeleton'

const Wrapper = styled.div`
  max-width: 450px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background-color: #fff;
  overflow: hidden;
  isolation: isolate;
  margin-top: 16px;

  @media ${device.laptop} {
    margin-top: 2rem;
  }
`
export const Heading = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  padding: 0 21px;
  height: 64px;
`

const Container = styled.div`
  width: 100%;
  height: 333px;

  overflow: auto;

  ${ScrollBar}

  padding: 18px;

  ${BodySmall} {
    margin-bottom: 20px;
    text-align: center;
    color: #475467;
  }

  /* @media ${device.tablet} {
    width: 100%;
  } */
`

const BookingCount = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1ab265;
  color: #ffff;
  margin-left: 20px;
`

export const NoRequest = styled.div`
  text-align: center;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

const Requests = () => {
  const { data, isLoading } = useFetchAppointmentRequest()

  let requests = []

  const count = data?.appointments.length

  if (data) {
    requests = data?.appointments.map((appointment: any) => {
      return {
        image: appointment?.user.avatar,
        id: appointment.id,
        name: appointment?.userDisplayName,
        reason: appointment.reason,
        note: appointment.note,
        date: appointment.started_at,
        end_time: appointment.ended_at,
      }
    })
  }

  return (
    <Wrapper>
      <Heading>
        <SubHeadingBold>Session Requests</SubHeadingBold>
        <BookingCount>
          <BodySmall fontSize={12}>{count ?? 0}</BodySmall>
        </BookingCount>
      </Heading>

      <Container>
        {/* LOADING SKELETON */}

        {isLoading && (
          <Stack spacing={20} zIndex={0}>
            <Skeleton height="50px" count={5} />
          </Stack>
        )}

        {/* WHEN THERE IS NO REQUEST */}
        {requests.length === 0 && !isLoading && (
          <NoRequest>
            <Empty />
            <BodySmall> You have no pending booking requests. </BodySmall>
          </NoRequest>
        )}

        {/* WHEN THERE IS REQUEST */}
        {requests.length !== 0 && (
          <>
            {requests.map((request: any) => (
              <Item
                id={request.id}
                key={request.id}
                name={request.name}
                image={request.image}
                reason={request.reason}
                note={request.note}
                date={request.date}
              />
            ))}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default Requests

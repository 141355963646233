import styled from 'styled-components'
import { ButtonProps } from './index'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

export enum ButtonState {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  OUTLINE = 'outline',
}

export const Button = styled.button<ButtonProps & LayoutProps & SpaceProps>`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s;
  /* identical to box height, or 150% */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  height: 3.25rem;
  border-radius: ${(props) =>
    props.border_radius ? props.border_radius : props.theme.borderRadius.sm};
  background: ${(props) => props.theme.buttonPalette[props.variant].background};
  color: ${(props) => props.theme.buttonPalette[props.variant].color};
  border: 1px solid ${(props) => props.theme.buttonPalette[props.variant].color};
  outline: none;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border: 1px solid
    ${(props) => props.theme.buttonPalette[props.variant].border};
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.7;
  }
  svg:first-child {
    padding-right: 0.438rem;
  }
  ${layout}
  ${space}
`

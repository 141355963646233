export const TOGGLE_MOBILE_MENU = '@therapist/modal/toggle_mobile_menu'
export const TOGGLE_SESSION_DETAILS = '@therapist/modal/toggle_session_details'

export interface ModalStateProps {
  show_sidebar: boolean
  show_session_details: boolean
}

export const modalState: ModalStateProps = {
  show_sidebar: false,
  show_session_details: false,
}

import styled from 'styled-components'
import { toRem } from 'helpers'
import { BodyNormalBold } from 'components/base/typography'

export const Wrapper = styled.div`
  width: 100%;
  /* height: ${toRem(200)}; */
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #ffffff;
  padding: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  border-left: 8px solid #1a244e;

  & p {
    margin: 0;
  }

  &:hover {
    opacity: 0.5;
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
`

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px;

  ${BodyNormalBold} {
    & span:last-child {
      margin-left: 10px;
    }
  }
`

export const Status = styled.div`
  border-radius: 27px;
  width: 100px;
  padding: 4px 16px;
  height: 32px;
  background: #eaecf5;
  color: #3e4784;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`

export const Image = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`

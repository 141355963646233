import HttpService from './HttpService'
import StorageService from './StorageService'
import AuthenticationService from './AuthenticationService'
import FetchDataService from './FetchDataService'
import AccountService from './AccountService'
import AppointmentService from './AppointmentService'
import QueryService from './QueryService'

// SERVICES CONTAINER
const httpService = new HttpService()

export const storageService = new StorageService()

const queryService = new QueryService(storageService)

export const fetchDataService = new FetchDataService(queryService)

export const accountService = new AccountService(queryService)

export const appointmentService = new AppointmentService(queryService)

export const authenticationService = new AuthenticationService(
  httpService,
  storageService
)

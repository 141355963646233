import {
  AccountWrapper,
  AccountHeading,
  AccountUpdateLink,
  AccountProfile,
  AccountStats,
  AccountAvatar,
  StatsBox,
  Status,
  AcceptanceRating,
  ResponseTime,
  AverageRating,
  InfoBox,
  PorfolioLink,
  AccountLinks,
} from './style'
import {
  SubHeadingBold,
  BodyNormalSemiBold,
  BodySmall,
} from 'components/base/typography'
import { Link, InfoIcon, RatingStar, Update as UpdateIcon } from 'assets/svg'
import useFetchDashboard from 'hooks/useFetchDashboard'
import Tooltip from '../Tooltip'
import { Box } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'
import {
  capitalizeFirstLetter,
  convertMinutesToHoursOrDays,
} from '../AccountTabWidget/helpers'
import useFetchAccount from 'hooks/account/useFetchAccount'

export const ProfoileSkeleton = () => {
  return (
    <Box>
      <AccountAvatar>
        <Skeleton width="100%" height="100%" />
      </AccountAvatar>

      <Skeleton />
      <Skeleton width="50%" height="100%" />
    </Box>
  )
}

const Account = () => {
  const { data, isLoading } = useFetchDashboard()
  const { data: account } = useFetchAccount()

  const portfolioURL = `https://${account?.username}.mytherapist.com.ng`

  return (
    <>
      <AccountWrapper>
        <AccountHeading>
          <SubHeadingBold>My Account</SubHeadingBold>
          <AccountLinks>
            <AccountUpdateLink to="/dashboard/account/manage-profile">
              <UpdateIcon />
              <BodySmall marginLeft={2}>Update account</BodySmall>
            </AccountUpdateLink>
            <PorfolioLink href={portfolioURL} target="_blank" rel="noreferrer">
              <BodySmall color={'#1ab265'} marginTop={0} marginRight={'8px'}>
                View Public Profile
              </BodySmall>
              <Link />
            </PorfolioLink>
          </AccountLinks>
        </AccountHeading>

        <AccountProfile>
          {isLoading ? (
            <ProfoileSkeleton />
          ) : (
            <>
              <AccountAvatar>
                <img
                  src={data?.avatar}
                  alt={data?.name}
                  style={{
                    width: '120px',
                    height: '120px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    borderWidth: data.is_prime ? '3px' : 0,
                    borderColor: '#FFD700',
                    borderStyle: 'solid',
                  }}
                />
              </AccountAvatar>
              <BodyNormalSemiBold color={'#101928'}>
                {data?.name}
              </BodyNormalSemiBold>
              <BodySmall>{data?.role}</BodySmall>
            </>
          )}
        </AccountProfile>
        <AccountStats>
          <StatsBox>
            <BodySmall>Rating</BodySmall>
            <AverageRating>
              <BodyNormalSemiBold
                lineHeight={0}
                fontSize={'14px'}
                marginRight={2}
              >
                {data?.rating ?? 1}
              </BodyNormalSemiBold>
              <RatingStar />
            </AverageRating>
          </StatsBox>
          <StatsBox>
            <InfoBox>
              <BodySmall>Avg / Response Time</BodySmall>
              <Tooltip content="This is how long it takes you to respond to booking requests and is visible to users.">
                <InfoIcon />
              </Tooltip>
            </InfoBox>

            <ResponseTime rating={Math.floor(data?.stats.response_time / 60)}>
              <BodyNormalSemiBold>
                {convertMinutesToHoursOrDays(data?.stats.response_time ?? 0)}
                {/* {Math.floor(data?.stats.response_time ?? 0 / 60)} hrs */}
              </BodyNormalSemiBold>
            </ResponseTime>
          </StatsBox>
          <StatsBox>
            <InfoBox>
              <BodySmall>Acceptance Rating</BodySmall>
              <Tooltip content="This score is based on your booking history and tells users how likely you are to accept their booking requests.">
                <InfoIcon />
              </Tooltip>
            </InfoBox>
            <AcceptanceRating rating={data?.stats.acceptance_rating ?? 100}>
              <BodyNormalSemiBold>
                {data?.stats.acceptance_rating ?? '100'}/100
              </BodyNormalSemiBold>
            </AcceptanceRating>
          </StatsBox>
          <StatsBox>
            <BodySmall>Total Clients</BodySmall>
            <BodyNormalSemiBold color={'#101928'}>
              {data?.stats.clients ?? '0'}
            </BodyNormalSemiBold>
          </StatsBox>
          <StatsBox>
            <BodySmall>Life-time Earnings</BodySmall>
            <BodyNormalSemiBold color={'#101928'}>
              {data?.stats?.lifetime_earnings?.display ?? '₦ 0.00'}
            </BodyNormalSemiBold>
          </StatsBox>
          <StatsBox>
            <BodySmall>Listing Status</BodySmall>
            <Status
              is_prime={data?.is_prime}
              status={data?.status ?? 'PENDING'}
            >
              <BodySmall lineHeight={0}>
                {data?.is_prime
                  ? 'Prime'
                  : data?.status
                  ? capitalizeFirstLetter(data?.status)
                  : 'PENDING'}
              </BodySmall>
            </Status>
          </StatsBox>
        </AccountStats>
      </AccountWrapper>
      {/* )} */}
    </>
  )
}

export default Account

import { CreatePrescriptionProps } from 'services/AppointmentService'

export const STEP_ONE = '@prescription/step-one'

export interface PrescriptionProps extends CreatePrescriptionProps {}

export const prescriptionState: PrescriptionProps = {
  title: '',
  user_id: 1,
  medications: [
    {
      medication_name: '',
      dosage: '',
      frequency: '',
      refills: 0,
      instructions: '',
      duration: '',
      intake: '',
    },
  ],
  notes: '',
}

import React, { useState } from 'react'
import styled from 'styled-components'
import AgoraUIKit from 'agora-react-uikit'
import { TherapistToast } from 'helpers'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

interface VoiceProps {
  token: string
  channelName: string
  handleEndCall: (type: 'voice' | 'video' | 'chat') => void
}
const VoiceChat: React.FC<VoiceProps> = ({
  token,
  channelName,
  handleEndCall,
}) => {
  const [voiceCall, setVoiceCall] = useState(true)
  const rtcProps = {
    appId: '4d3643b1f564412f8c65ac88e85aaf20',
    channel: channelName,
    videoCall: false,
    voiceCall: voiceCall,
    token: token, // use null or skip if using app in testing mode
  }
  const callbacks = {
    EndCall: () => {
      handleEndCall('chat')
      TherapistToast.success({ msg: 'Voice call Ended' })
      setVoiceCall(false)
    },
  }
  return voiceCall ? (
    <Wrapper>
      <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
    </Wrapper>
  ) : (
    <h3 onClick={() => setVoiceCall(true)}>Start Call</h3>
  )
}

export default VoiceChat

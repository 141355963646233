import { withUseQuery } from 'hoc/withUseQuery'

import { BodyLarge, BodyNormal, TitleThree } from 'components/base/typography'
import { Container } from '@chakra-ui/react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 400px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
`

const Tasks = () => {
  return (
    <Container>
      <Wrapper>
        <BodyLarge color={'#667185'} fontSize={'18px'}>
          No Task for this client
        </BodyLarge>
      </Wrapper>
    </Container>
  )
}

export default withUseQuery(Tasks)

import { DataAction } from '../../types'
import { reducerMultiplexer } from '../../utils'
import {
  authState,
  AuthStateProps,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAILED,
  CREATE_ACCOUNT_SUCCESS,
  SIGN_IN,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESS,
  STEP_ONE,
  TOGGLE_CONFIRM_PASSWORD,
  TOGGLE_PASSWORD,
} from './types'

export default function authReducer(state = authState, action: DataAction) {
  return reducerMultiplexer<AuthStateProps>({
    [CREATE_ACCOUNT]: (state, action) => {
      return {
        ...state,
        loading: action.data,
      }
    },
    [CREATE_ACCOUNT_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.data,
        show_repeat_password: false,
        show_password: false,
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: '',
        bio: '',
        role: '',
        specialized_in: '',
        linked_url: '',
        years_of_experience: 0,
        qualifications: '',
        password_confirmation: '',
        accept_terms: false,
        error: {},
      }
    },
    [CREATE_ACCOUNT_FAILED]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.data,
      }
    },
    [STEP_ONE]: (state, action) => {
      return {
        ...state,
        ...action.data,
      }
    },
    [TOGGLE_PASSWORD]: (state, action) => {
      return {
        ...state,
        show_password: !action.data,
      }
    },
    [TOGGLE_CONFIRM_PASSWORD]: (state, action) => {
      return {
        ...state,
        show_repeat_password: !action.data,
      }
    },
    [SIGN_IN]: (state, action) => {
      return {
        ...state,
        loading: action.data,
      }
    },
    [SIGN_IN_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [SIGN_IN_FAILED]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
  })(state, action)
}

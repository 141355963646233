import HttpService, {
  baseURL,
  FireRequestProps,
  Headers,
  HttpError,
} from './HttpService'
import StorageService from './StorageService'
import Axios, { AxiosInstance } from 'axios'

export default class QueryService extends HttpService {
  constructor(
    private storageService: StorageService,
    client: AxiosInstance = Axios.create({
      baseURL: baseURL,
    })
  ) {
    super(client)
  }

  private addAuthorizationHeader(headers: Headers): Headers {
    const { access_token } = this.storageService.getAuthData()
    if (access_token) {
      if (headers) {
        headers['Authorization'] = `Bearer ${access_token}`
      } else {
        headers = {
          Authorization: `Bearer ${access_token}`,
        }
      }
    }
    return headers
  }

  async fireRequest(
    fireRequestProps: FireRequestProps,
    authenticate = false
  ): Promise<any> {
    if (authenticate) {
      fireRequestProps.headers = this.addAuthorizationHeader(
        fireRequestProps.headers || {}
      )
    }
    try {
      return await super.fireRequest(fireRequestProps)
    } catch (e) {
      const httpError = e as HttpError
      switch (httpError.getStatusCode()) {
        default:
          throw httpError
      }
    }
  }
}

import React from 'react'
import styled from 'styled-components'
import { Plus, Search } from 'assets/svg'
import { ButtonState } from '../../Button/style'
import Buttton from 'components/libs/Button'
import { NoteStatusType } from '../helpers'

const BtnWrapper = styled.div`
  padding: 21px;
  margin-top: auto;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  row-gap: 12px;
  align-items: center;
`
export const InputWrapper = styled.div`
  border: 0.5px solid #d0d5dd;
  height: 56px;
  border-radius: 8px;
  overflow: hidden;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  & input {
    width: 100%;
    height: 100%;
    border: none;
    font-family: inherit;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #98a2b3;
    }
  }
`

interface HeadingProps {
  handleFilter: (searchTerm: string) => void
  handleNoteStatus: (status: NoteStatusType) => void
}

const Heading: React.FC<HeadingProps> = ({
  handleFilter,
  handleNoteStatus,
}) => {
  const handleSearch = (e: any) => {
    handleFilter(e.target.value)
  }
  return (
    <Wrapper>
      <InputWrapper>
        <Search />
        <input
          placeholder="Search notes"
          onChange={(e) => handleSearch(e)}
        />
      </InputWrapper>
      <BtnWrapper>
        <Buttton
          variant={ButtonState.PRIMARY}
          padding={'16px'}
          width={'100%'}
          border_radius={'8px'}
          type="button"
          icon={<Plus width={'21px'} />}
          onClick={() => {
            handleNoteStatus('new')
          }}
        >
          New Note
        </Buttton>
      </BtnWrapper>
    </Wrapper>
  )
}

export default Heading

import {
  Container,
  Card,
  Grid,
  Flex,
  IconBox,
  PercentageStats,
  PercentageBox,
} from './style'
import {
  BodySmall,
  BodySmallSemiBold,
  TitleThree,
} from 'components/base/typography'
import useFetchBalance from 'hooks/useFetchBalance'
import { WalletIcon, Time, Chat2, Graph } from 'assets/svg'
import { InfoBox } from './style'
import { InfoIcon } from 'assets/svg'
import Tooltip from '../Tooltip'
import useFetchDashboard from 'hooks/useFetchDashboard'
import useUpcomingAppointments from 'hooks/appointments/useUpcomingAppointments'
import moment from 'moment'

const Overview = () => {
  const { data: currentBalance } = useFetchBalance(true)
  const { data: upcoming } = useUpcomingAppointments()

  const { data: dashboard } = useFetchDashboard()

  let overview: any[] = []

  const computeStatsPercentage = (current: number, last30Days: number) => {
    const percentage = (current / last30Days) * 100

    return Number.isNaN(percentage) ? 0 : percentage
  }

  // Get today's date

  const today = moment().startOf('day')

  // Filter appointments happening today
  const happeningToday = upcoming?.filter(
    (appointment: Record<string, any>) => {
      const appointmentDate = moment(appointment.started_at).startOf('day')
      return appointmentDate.isSame(today, 'day')
    }
  )

  overview = [
    {
      name: 'Total Sessions',
      value: dashboard?.stats.total_appointment ?? 0,
      icon: <Chat2 />,
      description:
        'This is the total number of sessions you’ve had in the past and currently.',
      percentage: Math.floor(
        computeStatsPercentage(
          dashboard?.stats.total_appointment,
          dashboard?.stats.total_appointments_last_30_days
        )
      ),
    },
    {
      name: 'Wallet Balance',
      value: currentBalance?.balance?.display ?? '₦ 0.00',
      icon: <WalletIcon />,
      description:
        'This is the current balance from your earnings & other activities',
      percentage: Math.floor(
        computeStatsPercentage(
          +currentBalance?.balance?.amount,
          +dashboard?.stats.earnings_last_30_days.amount
        )
      ),
    },
    {
      name: 'Upcoming Sessions',
      value: dashboard?.stats.upcoming_appointments ?? 0,
      icon: <Time />,
      description: 'This is the total number of sessions coming up for you.',
      information: `${
        happeningToday?.length === 0 ? 'None' : happeningToday?.length ?? 'None'
      } happening today`,
    },
  ]

  return (
    <Container>
      <Grid>
        {overview.map((stats) => (
          <Card key={stats.name}>
            <InfoBox>
              <BodySmallSemiBold color={'#5D6673'}>
                {stats.name}
              </BodySmallSemiBold>
              <Tooltip content={stats.description}>
                <InfoIcon />
              </Tooltip>
            </InfoBox>

            <Flex>
              <TitleThree>{stats.value}</TitleThree>
              <IconBox>{stats.icon}</IconBox>
            </Flex>
            {stats.information ? (
              <BodySmall color={'#9EA3AB'} fontSize={'12px'}>
                {stats.information}
              </BodySmall>
            ) : (
              <PercentageBox>
                <PercentageStats rating={stats.percentage}>
                  <Graph />
                  <BodySmall fontSize={'12px'}>{stats.percentage}%</BodySmall>
                </PercentageStats>
                <BodySmall color={'#9EA3AB'} fontSize={'12px'}>
                  Last 30 days
                </BodySmall>
              </PercentageBox>
            )}
          </Card>
        ))}
      </Grid>
    </Container>
  )
}

export default Overview

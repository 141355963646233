import { TextAreaHolder } from './style'
import { FC } from 'react'
import { Error } from '../FormInput/style'
import { withTheme } from 'hoc/withTheme'

interface TextAreaProps {
  id: string
  label: string
  name: string

  [key: string]: any
}

const TextArea: FC<TextAreaProps> = ({ id, name, label, formik, ...props }) => {
  return (
    <TextAreaHolder data-testid={'therapist-input-holder'}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        data-testid="therapist-textarea"
        {...props}
        value={formik?.values[name] || props.value}
        name={name}
      ></textarea>
      {formik && formik?.errors[name] && <Error>{formik?.errors[name]}</Error>}
    </TextAreaHolder>
  )
}

export default withTheme<TextAreaProps>(TextArea)

import styled from 'styled-components'
import { ScrollBar } from 'styles/global'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  height: 100vh;
  overflow: hidden;
`

export const ChatInput = styled.form`
  height: 79px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 29px;
  border: 1px solid #eaecf0;
  padding: 0 20px;

  & div {
    flex: 1;

    & div {
      border-radius: 24px;
      overflow: hidden;
    }
  }

  & button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }
`

export const ChatWrapper = styled.div`
  /* height: 100%; */
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: 1fr 79px;
`

export const ChatBox = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 0 15px 15px 15px;
  ${ScrollBar}
`

export const ClientMessage = styled.div`
  height: max-content;
  width: fit-content;
  margin-right: auto;
  margin-top: 20px;
  & div {
    background-color: #fff4c6;
    width: 100%;
    color: #344054;
    padding: 8px 12px;
    border-radius: 8px 8px 8px 0px;
  }
  & span {
    gap: 10px;
    display: flex;
    color: #98a2b3;
    text-align: left;
    margin-top: 5px;
  }
`

export const TherapistMessage = styled.div`
  height: max-content;
  width: fit-content;
  margin-left: auto;
  margin-top: 40px;

  & div {
    background-color: #ccffe5;
    width: 100%;
    color: #344054;
    padding: 8px 12px;
    border-radius: 8px 8px 0px 8px;
  }

  & span {
    display: block;
    color: #98a2b3;
    text-align: right;
    margin-top: 5px;
  }
`

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { appointmentService } from 'services'
import { TherapistToast } from 'helpers'
import { AcceptProps } from 'services/AppointmentService'

const fetcher = async (data: AcceptProps) => {
  const response = await appointmentService.acceptAppointment(data)

  return response
}

const useAcceptAppointment = () => {
  const queryClient = useQueryClient()

  const handleSuccess = () => {
    TherapistToast.success({ msg: 'Appointment accepted successfully' })
  }

  const handleError = () => {
    TherapistToast.error({ msg: 'An Error occured. Try again later' })
  }

  const mutate = useMutation((id: AcceptProps) => fetcher(id), {
    onError: () => handleError(),
    onSuccess: () => {
      queryClient.invalidateQueries(['appointment-request'])
      handleSuccess()
    },
  })

  return mutate
}

export default useAcceptAppointment

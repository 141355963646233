import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { TitleThree, BodyNormal } from 'components/base/typography'

interface Props {
  show: boolean
}

export const ListGrid = styled.div<Props>`
  display: grid;
  grid-template-columns: ${(p) =>
    p.show
      ? `repeat(auto-fit, minmax(200px, 380px))`
      : `repeat(auto-fit, minmax(150px, 350px))`};
  row-gap: 25px;
  column-gap: 32px;
  transition: all 0.2s;

  @media ${device.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(150px, 350px));
  }

  @media ${device.mobile} {
    justify-content: center;
  }
`

export const Wrapper = styled.div`
  padding: 20px;
  margin-bottom: 40px;

  ${TitleThree} {
    margin-bottom: 15px;
  }
`

export const SessionContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: ${(p) => (p.show ? `1fr 380px` : `1fr`)};
  grid-template-columns: 1fr;
  margin-top: 25px;
`

export const DesktopSessionDetails = styled.aside`
  display: block;

  @media ${device.laptop} {
    display: none;
  }
`

// TABLE

interface StatusCardProps {
  status: string
}

export const StatusCard = styled.div<StatusCardProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  background: ${(props) =>
    props.status === 'accepted' || props.status === 'completed'
      ? '#ECFDF3'
      : props.status === 'pending' || props.status === 'unattended'
      ? '#FFEDDD'
      : props.status === 'cancelled' ||
        props.status === 'rejected' ||
        props.status === 'missed'
      ? '#FFD6D7'
      : 'red'};

  color: ${(props) =>
    props.status === 'accepted' || props.status === 'completed'
      ? '#1AB265'
      : props.status === 'pending' || props.status === 'unattended'
      ? '#FE810E'
      : props.status === 'cancelled' ||
        props.status === 'rejected' ||
        props.status === 'missed'
      ? 'red'
      : 'red'};
`

export const TableWrapper = styled.div`
  padding: 20px 30px;
  padding-left: 0;

  @media ${device.tablet} {
    padding: 20px 20px;
  }

  ${BodyNormal} {
    text-transform: capitalize;
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  & > img {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-right: 12px;
  }
`

interface RatingProps {
  rating: number
}

export const ResponseTime = styled.span<RatingProps>`
  color: ${(props) =>
    props.rating < 2
      ? '#1AB265'
      : props.rating <= 5
      ? '#FE810E'
      : props.rating > 5
      ? 'red'
      : 'red'};
`

export const ViewMore = styled.button`
  border: 1px solid #e4e7ec;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CalendarContainer = styled.div`
  padding: 20px 30px;
  margin-top: 32px;
  padding-right: 15px;

  @media ${device.laptopL} {
    width: 50%;
  }

  @media ${device.tablet} {
    padding: 20px 20px;
    width: 100%;
  }

  ${BodyNormal} {
    text-transform: capitalize;
  }
`

export const CalendarWrapper = styled.div`
  background-color: #ffff;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  overflow: hidden;

  & .react-calendar {
    border-radius: 0 !important;
    border-right: unset !important;
    border-left: unset !important;
    border-bottom: unset !important;
  }
`

export const CalendarHeader = styled.div`
  padding: 20px 30px;
`

export const ToggleContainer = styled.div`
  padding: 10px 30px;
  margin-top: 20px;
`
export const ActiveDay = styled.span`
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #1b1d3e;
  border-radius: 50%;
  bottom: 2.5px;
  left: 0;
  right: 0;
  margin: 0 auto;
`

export const SessionCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
`

export const Analytics = styled.div`
  padding: 20px 30px;
  padding-bottom: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 16px;
  gap: 15px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;

  @media ${device.laptopL} {
    grid-template-columns: 100%;
  }

  @media ${device.tablet} {
    grid-template-columns: 100%;
  }
`

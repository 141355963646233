import FormInput from 'components/libs/Input/FormInput'
import TextArea from 'components/libs/Input/TextArea'
import useRejectAppointment from 'hooks/appointments/useRejectAppointment'
import Loader from 'components/libs/Loader'
import { TitleTwo } from 'components/base/typography'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'

interface Props {
  id: number
  closeModal: () => void
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`

const DeclineReason: React.FC<Props> = ({ id, closeModal }) => {
  const { mutate, isLoading } = useRejectAppointment()

  const schema = Yup.object({
    reason: Yup.string().required('Reason for declining is required'),
  })
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      mutate({ id, reason: values.reason })
      closeModal()
      resetForm()
    },
  })
  return (
    <form
      onSubmit={formik.handleSubmit}
      data-testId={'therapist-decline-appointment'}
    >
      <TitleTwo>Decline Request</TitleTwo>
      <TextArea
        type={'text'}
        name={'reason'}
        id={'reason'}
        label={'What is your reason for declining  this request?'}
        formik={formik}
        value={formik.values.reason}
        placeholder={'Type here'}
        onChange={formik.handleChange}
      />

      <Actions>
        <Button
          variant={ButtonState.OUTLINE}
          padding={'20px 40px'}
          type={'button'}
          onClick={closeModal}
          border_radius="7px"
        >
          Cancel
        </Button>

        <Button
          variant={ButtonState.PRIMARY}
          padding={'20px 40px'}
          type={'submit'}
          border_radius="7px"
        >
          {isLoading ? (
            <Loader width={'30px'} height={'30px'} color={'#fff'} size={'4'} />
          ) : (
            'Decline'
          )}
        </Button>
      </Actions>
    </form>
  )
}

export default DeclineReason

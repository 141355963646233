import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'
import { BodyLargeBold } from 'components/base/typography'
import { authenticationService } from 'services'
import { useNavigate } from 'react-router-dom'

interface Props {
  closeModal: () => void
}

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-right: auto;
  margin-top: 60px;
`

const Info = styled.div`
  text-align: center;
  margin-top: 40px;
  color: #344054;
`
const Logout: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate()
  const handleLogout = () => {
    authenticationService.signOut()

    navigate('/login')
  }
  return (
    <div data-testId={'therapist-log-out'}>
      <Info>
        <BodyLargeBold>Are you sure you want to log out?</BodyLargeBold>
      </Info>
      <Action>
        <Button
          variant={ButtonState.TERTIARY}
          padding={'20px 30px'}
          type={'button'}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonState.PRIMARY}
          padding={'20px 30px'}
          type={'button'}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Action>
    </div>
  )
}

export default Logout

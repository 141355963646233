import { useQuery } from '@tanstack/react-query'
import { appointmentService } from 'services'

const fetcher = async ({ queryKey }: any) => {
  const appointmentID = +queryKey[1]

  const response = await appointmentService.getAppointment(appointmentID)

  return response?.appointment
}

const useFetchAppointment = (appointmentID: any) => {
  return useQuery(['appointment', appointmentID], fetcher, {
    enabled: !!appointmentID,
  })
}

export default useFetchAppointment

import Header from './Header'
import Table from 'components/libs/Table'
import { device } from 'helpers/breakpoints'
import useFetchTransactions from 'hooks/useFetchTransactions'
import styled from 'styled-components'
import { BodyNormal } from 'components/base/typography'
import { formatDate } from 'helpers'

import { withUseQuery } from 'hoc/withUseQuery'

interface Props {
  [key: string]: any
}
interface StatusCardProps {
  status: string
}

const StatusCard = styled.div<StatusCardProps>`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  background: ${(props) =>
    props.status === 'pending'
      ? '#FEF0C7'
      : props.status === 'rejected'
      ? '#e78a8a'
      : '#D1FADF'};
`

const Wrapper = styled.div`
  padding: 20px 40px;
  overflow: auto;
  height: 800px;
  overflow: hidden;

  @media ${device.tablet} {
    padding: 20px 20px;
  }

  ${BodyNormal} {
    text-transform: capitalize;
  }
`

const Transactions = () => {
  const { data, isLoading } = useFetchTransactions()

  const transactions = data?.transactions

  return (
    <>
      <Header />

      <Wrapper>
        <Table
          label="Transactions"
          columns={[
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              key: 'amount',
            },
            {
              title: 'Time',
              dataIndex: 'time',
              key: 'time',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
          ]}
          loading={isLoading}
          dataSource={
            transactions && transactions.length > 0
              ? transactions.map((data: Props) => {
                  return {
                    key: `${data.id} `,
                    date: (
                      <BodyNormal color={'#1B1D3E'}>
                        {formatDate(data.created_at).date}
                      </BodyNormal>
                    ),
                    type: (
                      <BodyNormal
                        color={data.type === 'credit' ? ' #1AB265' : '#CE2424'}
                      >
                        {data.type}
                      </BodyNormal>
                    ),
                    amount: (
                      <BodyNormal color={'#1B1D3E'}>
                        {data.amount?.display}
                      </BodyNormal>
                    ),

                    time: (
                      <BodyNormal color={'#1B1D3E'}>
                        {formatDate(data.created_at).time}
                      </BodyNormal>
                    ),
                    status: (
                      <StatusCard status={'Successful'}>
                        <BodyNormal color={'#12B76A'}>Successful</BodyNormal>
                      </StatusCard>
                    ),
                  }
                })
              : []
          }
        />
      </Wrapper>
    </>
  )
}

export default withUseQuery(Transactions)

import styled from 'styled-components'
import { LoadingIndicator } from 'assets/svg'
import { layout, LayoutProps, ColorProps } from 'styled-system'

type BaseProps = ColorProps & LayoutProps

const Wrapper = styled.div<BaseProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  margin: 0 auto;

  & span {
    display: block !important;
  }

  svg {
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }
  circle {
    fill: none;
    stroke: ${(p) => p.color};
    stroke-width: ${(p) => p.size};
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }
`

interface LoaderProps {
  width: string
  height: string
  color: string
  size: string
}
const Loader: React.FC<LoaderProps> = ({ width, height, color, size }) => {
  return (
    <Wrapper width={width} height={height} color={color} size={size}>
      <span>
        <LoadingIndicator />
      </span>
    </Wrapper>
  )
}

export default Loader

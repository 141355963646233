import { DataAction } from '../../types'
import { reducerMultiplexer } from '../../utils'
import { PrescriptionProps, prescriptionState } from './types'

import { STEP_ONE } from './types'

export default function authReducer(
  state = prescriptionState,
  action: DataAction
) {
  return reducerMultiplexer<PrescriptionProps>({
    [STEP_ONE]: (state, action) => {
      return {
        ...state,
        ...action.data,
      }
    },
  })(state, action)
}

/* eslint-disable no-octal */
import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { TitleThree, Paragraph } from 'components/base/typography'
import { ScrollBar } from 'styles/global'

export const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
  ${ScrollBar}
`
export const Heading = styled.div`
  ${TitleThree} {
    margin-bottom: 4px;
  }
`

export const Grid = styled.div`
  margin-top: 46px;
  display: grid;
  grid-auto-rows: max-content;

  @media ${device.tablet} {
    margin-top: 20px;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 40px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: revert;
  }

  & > div {
    @media ${device.tablet} {
      flex: 1;
    }
  }
`

export const AvaliabilityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
  margin-top: 20px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  & > div {
    /* flex: 1 1 400px; */

    @media ${device.tablet} {
      flex: 1;
    }
  }

  & > span {
    flex: 1;
  }

  ${Paragraph} {
    flex: 4;
  }
`
// TOGGLE SWITCH STYLING
export const Switch = styled.div`
  width: 36px;
  height: 20px;
  border: 1px solid #f2f4f7;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  padding: 2px;
  background-color: #ccc;
  transition: all 0.4s ease-out;

  & div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 1.5px;
    left: 1px;
    right: unset;
    transition: all 0.4s ease-out;
  }

  &[data-active='true'] {
    border: 1px solid #1b1d3e;
    background-color: #1b1d3e;

    & div {
      left: 17px;
    }
  }
`
export const Label = styled.div`
  flex: 1 1 100px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`
export const InputBox = styled.div`
  flex: 1 1 600px;

  & span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

export const SelectBox = styled.div`
  flex: 4;

  & div {
    display: flex;
    align-items: center;

    @media ${device.tablet} {
      flex-direction: column;
    }

    & div {
      width: 100%;

      & label {
        margin-right: auto;
      }

      &:not(:first-child) {
        margin-left: 40px;

        @media ${device.tablet} {
          margin-left: 0px;
        }
      }

      & select {
        width: 100%;
      }
    }
  }

  @media ${device.tablet} {
    flex: 1;
    width: 100%;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const Action = styled.div`
  border-top: 1px solid #eaecf0;
  padding-top: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button {
    width: 206px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }
`

export const CheckBox = styled.label`
  color: #344054;
  display: block;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  text-transform: capitalize;
  margin-bottom: 15px;
  & input {
    width: 20px;
    height: 20px;
    opacity: 0; // Hide the native HTML CheckBox
    &:checked ~ span {
      background-color: #1b1d3e;
    }
    &:checked ~ span svg {
      display: block;
    }
  }
  ${Paragraph} {
    margin-top: -2px;
  }
`

// Styles for the Custom Checkbox

export const CustomCheckBox = styled.span`
  width: 20px;
  height: 20px;
  border: 1px solid #d0d5dd;
  background-color: #fff;
  border-radius: 6px;
  color: #fff;
  transition: all 0.2s linear;
  position: absolute;
  top: 0;
  left: 0;
  & svg {
    display: none;
  }
`

export const ErrorText = styled.span`
  color: #f04438;
  /* margin-top: 100px; */
`

export const Info = styled.span`
  color: #1ab265;
`

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { appointmentService } from 'services'
import { TherapistToast } from 'helpers'
import { RejectProps } from 'services/AppointmentService'

const fetcher = async (data: RejectProps) => {
  const response = await appointmentService.rejectAppointment(data)

  return response
}

const useRejectAppointment = () => {
  const queryClient = useQueryClient()

  const handleSuccess = () => {
    TherapistToast.success({ msg: 'Appointment rejected!' })
  }

  const handleError = () => {
    TherapistToast.error({ msg: 'An Error occurred. Try again later' })
  }

  const mutate = useMutation((id: RejectProps) => fetcher(id), {
    onError: () => handleError(),
    onSuccess: () => {
      queryClient.invalidateQueries(['appointment-request'])

      handleSuccess()
    },
  })

  return mutate
}

export default useRejectAppointment

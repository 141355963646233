import styled from 'styled-components'
import { ScrollBar } from 'styles/global'
import * as Tabs from '@radix-ui/react-tabs'
import { device } from 'helpers/breakpoints'
import { BodyNormal } from 'components/base/typography'
import { tabs } from './helpers'

const Wrapper = styled(Tabs.List)`
  height: 50px;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 2px solid #eaecf0;
  display: flex;
  align-items: center;
  gap: 40px;
  ${ScrollBar}

  @media ${device.tablet} {
    overflow: auto;
    gap: 40px;
  }
`

const Tab = styled(Tabs.Trigger)`
  cursor: pointer;
  position: relative;
  flex: 1 0 0;
  height: 100%;
  background-color: inherit;
  border: none;

  & p {
    transition: all 0.2s;
  }

  &:hover {
    & p {
      color: #1ab265;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  &[data-state='active'] {
    box-shadow: inset 0 -1px 0 0 #1ab265, 0 1px 0 0 #1ab265;

    @media ${device.tablet} {
      box-shadow: inset 0 -2px 0 0 #1ab265, 0 1px 0 0 #1ab265;
    }

    & p {
      color: #1ab265;
    }
  }
`

const Header = () => {
  return (
    <Wrapper aria-label="Manage your Client">
      {tabs.map((tab) => (
        <Tab value={tab.tabType} key={tab.name}>
          <BodyNormal color={'#667085'}>{tab.name}</BodyNormal>
        </Tab>
      ))}
    </Wrapper>
  )
}

export default Header

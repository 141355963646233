import React, { useEffect } from 'react'
import GlobalStyles from 'styles/global'
import { withTheme } from 'hoc/withTheme'
import RoutesContainer from 'controllers'
import { Toaster } from 'react-hot-toast'
import { useIntercom } from 'react-use-intercom'
import useFetchAccount from 'hooks/account/useFetchAccount'

function App() {
  const { boot } = useIntercom()
  const { data } = useFetchAccount()

  const handleBootIntercom = () => {
    if (data) {
      boot({
        name: `${data.name}`,
        email: `${data.email}`,
      })
    }
  }

  useEffect(() => {
    handleBootIntercom()
  }, [data])

  return (
    <>
      <GlobalStyles />
      <RoutesContainer />
      <Toaster position="bottom-right" reverseOrder={true} />
    </>
  )
}

export default withTheme(App)

import { withUseQuery } from 'hoc/withUseQuery'

import { Container } from '@chakra-ui/react'
import styled from 'styled-components'
import SearchInput from '../Input/SearchInput'
import Button from '../Button'
import { ButtonState } from '../Button/style'
import { PlusIcon } from '@radix-ui/react-icons'
import Modal from 'components/libs/Modal'
import { useState } from 'react'
import CreatePrescription from '../Modal/CreatePrescription'

import moment from 'moment'

import { TableWrapper } from './styles'

import Table from 'components/libs/Table'
import { BodyNormal } from 'components/base/typography'

import 'styles/calendar.css'
import { useParams } from 'react-router-dom'
import useFetchClientPrescriptions from 'hooks/clients/useFetchClientPrescriptions'
import PrescriptionCreationSuccess from '../Modal/CreatePrescription/success'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { StatusCard } from 'pages/bookings/style'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export interface DetailsType {
  name: string
  reason: string
  description: string
  time: string
  image: string
  id: string
  appointmentId: number
  type: string
}
interface Props {
  [key: string]: any
}

const Prescriptions = () => {
  const { id } = useParams()
  const { data: account } = useFetchAccount()

  const { data, isLoading } = useFetchClientPrescriptions(Number(id))

  const [showSuccessModal, setShowSuccessModal] = useState(false)


  const [showInfo, setShowInfo] = useState(false)
  const [modalWith, setModalWidth] = useState('90%')

  const handleShowModal = () => {
    setShowInfo(!showInfo)
  }

  const handleSetModalWidth = (width: string) => {
    setModalWidth(width)
  }

  const handleShowSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  let prescriptions = data?.data

  return (
    <Container>
      <Header>
        <SearchInput placeholder={'Search...'} />
        <Button
          variant={ButtonState.PRIMARY}
          padding={'8px 14px'}
          type="button"
          onClick={handleShowModal}
          disabled={!account?.can_prescribe}
        >
          <PlusIcon color="white" width={30} height={30} />
          New Prescription
        </Button>
      </Header>

      <Container>
        <TableWrapper>
          <Table
            label=""
            columns={[
              {
                title: 'Date Created',
                dataIndex: 'date',
                key: 'date',
              },
              {
                title: 'Prescription Title',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Total Medications',
                dataIndex: 'totalMedications',
                key: 'totalMedications',
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
              },

              // {
              //   title: 'Actions',
              //   dataIndex: 'actions',
              //   key: 'action',
              // },
            ]}
            loading={isLoading}
            emptyListMessage={
              !account?.can_prescribe
                ? 'Sorry, only Licensed Psychiatrists can prescribe medications.'
                : 'No prescriptions found.'
            }
            dataSource={
              prescriptions && prescriptions.length > 0
                ? prescriptions.map((data: Props) => {
                    return {
                      key: `${data.id}`,
                      date: (
                        <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                          {moment(data.created_at).format('Do MMM YYYY')}
                        </BodyNormal>
                      ),
                      title: (
                        <BodyNormal fontSize={'14px'} color={'#1B1D3E'}>
                          {data?.title}
                        </BodyNormal>
                      ),
                      totalMedications: (
                        <BodyNormal fontSize={'14px'} color={'#1B1D3E'}>
                          {data?.medications?.length}
                        </BodyNormal>
                      ),
                      status: (
                        <StatusCard
                          status={data?.is_active ? 'accepted' : 'missed'}
                        >
                          <BodyNormal fontSize={'14px'}>
                            {data?.is_active ? 'Active' : 'Disabled'}
                          </BodyNormal>
                        </StatusCard>
                      ),
                    }
                  })
                : []
            }
            // paginationData={appointmentsData?.meta?.links}
            // currentPage={currentPage}
            // handlePagination={handlePagination}
            // handleAction={handleOpenModal}
          />
        </TableWrapper>
      </Container>

      <Modal show={showInfo} handleAction={handleShowModal} width={modalWith}>
        <CreatePrescription
          handleSuccess={handleShowSuccessModal}
          closeModal={handleShowModal}
          changeModalWidth={handleSetModalWidth}
        />
      </Modal>

      <Modal
        show={showSuccessModal}
        handleAction={handleShowSuccessModal}
        width={'500px'}
      >
        <PrescriptionCreationSuccess closeModal={handleShowSuccessModal} />
      </Modal>
    </Container>
  )
}

export default withUseQuery(Prescriptions)

import { FC } from 'react'
import { InputContainer, InputHolder } from '../FormInput/style'
import { withTheme } from 'hoc/withTheme'
import { Search } from 'assets/svg'
import styled from 'styled-components'

interface FormInputProps {
  label?: string
  type?: string

  [key: string]: any
}

const Icon = styled.span`
  margin-left: 10px;
`

const FormInput: FC<FormInputProps> = ({
  label,
  type = 'text',
  id,
  handleChange,
  info,
  placeholder,
  ...props
}) => {
  return (
    <InputHolder data-testid={'therapist-input-holder'}>
      <label htmlFor={id}>{label}</label>
      <InputContainer>
        <Icon>
          <Search />
        </Icon>

        <input
          data-testid={'therapist-form-input'}
          type={type}
          id={id}
          value={props.value}
          placeholder={placeholder}
          onChange={handleChange}
          {...props}
        />
      </InputContainer>
    </InputHolder>
  )
}

export default withTheme<FormInputProps>(FormInput)

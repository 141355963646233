import {
  BodyNormal,
  BodyNormalBold,
  BodyNormalSemiBold,
  BodySmallSemiBold,
} from 'components/base/typography'
import { Wrapper, Profile, Details, Status, Image } from './style'
import { formatDate } from 'helpers'
import moment from 'moment'
import { ChevronRightIcon } from '@radix-ui/react-icons'

interface SessionCardProps {
  name: string
  reason: string
  status: string
  startedAt: string
  endedAt: string
  image: string
  handleClick?: () => void
}

const SessionCard: React.FC<SessionCardProps> = ({
  name,
  reason,
  status,
  startedAt,
  endedAt,
  image,
  handleClick,
}) => {
  const handleDateFormat = (time: string) => {
    const formattedTime = moment(time).format('h:mm a')
    const formattedDate = moment(time).isSame(moment())
      ? 'Today'
      : moment(time).format('Do MMM YYYY')

    return {
      date: formattedDate,
      time: formattedTime,
    }
  }
  return (
    <Wrapper onClick={handleClick}>
      <BodyNormalBold color={'#667085'} data-testid={'time'}>
        <span>
          {handleDateFormat(startedAt).time} - {handleDateFormat(endedAt).time} (1 hour)
        </span>
      </BodyNormalBold>
      <Profile>
        <Image>
          <img src={image} alt="A man" />
        </Image>
        <div>
          <BodyNormalSemiBold color={'#344054'} data-testid={'name'}>
            {name}
            {/* <ChevronRightIcon /> */}
          </BodyNormalSemiBold>

          <BodyNormal color={'#667085'} data-testid={'reason'}>
            {reason}
          </BodyNormal>
        </div>
      </Profile>

      <Details>
        <Status data-testid={'status'}>
          <BodySmallSemiBold>{status}</BodySmallSemiBold>
        </Status>

        <BodyNormalBold color={'#667085'} data-testid={'time'}>
          <span>{handleDateFormat(startedAt).date}</span>
        </BodyNormalBold>
      </Details>
    </Wrapper>
  )
}

export default SessionCard

import React, { FC } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function withUseQuery<T>(Component: FC<any>) {
  return (props: T) => (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  )
}

export { withUseQuery }

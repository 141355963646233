import styled from 'styled-components'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Cancel } from 'assets/svg/index'
import { animated, useTransition } from '@react-spring/web'
import '@reach/dialog/styles.css'

const Icon = styled.span`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;

  & svg {
    color: #344054;
  }
`

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

interface ModalProps {
  show: boolean
  children: React.ReactNode
  handleAction: () => void
  width: string
  direction: 'right' | 'left'
  display: 'desktop' | 'mobile'
}

const SideModal: React.FC<ModalProps> = ({
  show,
  children,
  handleAction,
  width,
  direction,
  display,
}) => {
  const transitions = useTransition(show, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  })

  const close = () => handleAction()

  return (
    <div>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay
              style={{
                opacity: styles.opacity,
                zIndex: 2,
                display:
                  display === 'mobile'
                    ? `${window.innerWidth <= 1024 ? 'block' : 'none'}`
                    : 'block',
              }}
              onDismiss={close}
            >
              <AnimatedDialogContent
                aria-labelledby="dialog-title"
                style={{
                  transform: styles.y.to(
                    (value) => `translate3d(0px, ${value}px, 0px)`
                  ),
                  border: '1px solid #eaecf0',
                  borderRadius: 8,
                  width: `${width}`,
                  margin: 0,
                  marginLeft: `${direction === 'right' ? 'auto' : 0}`,
                  paddingLeft: 0,
                  height: '100vh',
                  overflow: 'hidden',
                }}
              >
                <Icon onClick={close}>
                  <Cancel />
                </Icon>

                {children}
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </div>
  )
}

export default SideModal

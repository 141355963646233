import styled from 'styled-components'
import UnstyledButton from 'components/libs/UnstyledButton'
import { toRem } from 'helpers/index'
import { device } from 'helpers/breakpoints'
import { Bars } from 'assets/svg'
import {
  TitleThree,
  BodySmall,
  SubHeadingBold,
} from 'components/base/typography'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { useDispatch } from 'react-redux'
import { toggleMobileMenu } from 'store/modules/modals/actions'

const Wrapper = styled.header`
  height: 110px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 28px;
  background: #ffffff;
  border: 1px solid #eaecf0;

  ${BodySmall} {
    margin-top: 5px;

    @media (max-width: 820px) {
      display: none;
    }
  }

  @media ${device.laptop} {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${TitleThree} {
      font-size: ${toRem(20)};
    }
  }

  & button {
    display: none;

    @media ${device.laptop} {
      display: block;
    }
  }
`

const Header = () => {
  const { data } = useFetchAccount()
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }

  return (
    <Wrapper>
      <UnstyledButton onClick={handleToggle}>
        <Bars width={24} height={25} color="#667085" />
      </UnstyledButton>
      <SubHeadingBold data-testid={'header-greeting'}>
        Hey 👋 {data?.name} !
      </SubHeadingBold>
      <BodySmall color={'#475467'}>
        Welcome to your dashboard, where you can help make the world a better
        place ❤️
      </BodySmall>
    </Wrapper>
  )
}

export default Header

import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

const fetcher = async () => {
  const response = await fetchDataService.getUpcomingAppointments()

  return response?.appointments
}

const useUpcomingAppointments = () => {
  return useQuery(['upcoming-appointments'], fetcher)
}

export default useUpcomingAppointments

import Loader from 'components/libs/Loader'
import { BodySmall, TitleTwo } from 'components/base/typography'

import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'
import SignaturePad from 'react-signature-canvas'
import { useRef, useState } from 'react'
import { toRem } from 'helpers'
import { ChevronLeft } from 'assets/svg'
import { TrashIcon } from '@radix-ui/react-icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import useCreatePrescription from 'hooks/clients/useCreatePrescription'

interface Props {
  closeModal: () => void
  handNextStep: (step: 'one' | 'two') => void
  handleSuccess: () => void
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #e4e7ec;
  height: ${toRem(200)};
  border-radius: ${toRem(8)};
  margin-top: 40px;
`
const BackButton = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const DeleteButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #e4e7ec;
  border-radius: 50%;
`

const CreatePrescription: React.FC<Props> = ({
  closeModal,
  handNextStep,
  handleSuccess,
}) => {
  const [showError, setShowError] = useState(false)
  const prescriptionState = useSelector(
    (state: RootState) => state.prescription
  )

  const { mutate, isLoading } = useCreatePrescription()

  const [trimmedDataURL, setTrimmedDataURL] = useState(null)
  const sigPadRef = useRef<any>(null)

  const clear = () => {
    if (sigPadRef.current) {
      sigPadRef.current.clear()
    }
  }

  const trim = async () => {
    if (sigPadRef.current) {
      const trimmedDataURL = await sigPadRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png')
      setTrimmedDataURL(trimmedDataURL)
    }
  }

  const handleSubmit = () => {
    trim()

    if (!trimmedDataURL) {
      setShowError(true)
    } else {
      setShowError(false)

      mutate(prescriptionState, {
        onSuccess: (data) => {
          closeModal()
          handleSuccess()
        },
      })
    }
  }
  return (
    <div>
      <TitleTwo>Save Prescription</TitleTwo>

      <BodySmall color={'#667085'}>
        Add your signature to complete and save prescription.
      </BodySmall>

      {showError && (
        <BodySmall color="#FF4141">
          Please sign to authenticate the prescription
        </BodySmall>
      )}

      <FormContainer>
        <SignaturePad
          ref={sigPadRef}
          canvasProps={{ width: 500, height: 500 }}
        />
      </FormContainer>

      <DeleteButton onClick={clear}>
        <TrashIcon color="#FF4141" width={20} height={30} />
      </DeleteButton>

      <Actions>
        <BackButton onClick={() => handNextStep('one')}>
          <ChevronLeft />
          <BodySmall>Back</BodySmall>
        </BackButton>
        <Button
          variant={ButtonState.OUTLINE}
          padding={'20px 40px'}
          type={'button'}
          onClick={closeModal}
          border_radius="7px"
        >
          Cancel
        </Button>

        <Button
          variant={ButtonState.PRIMARY}
          padding={'20px 20px'}
          type={'button'}
          border_radius="7px"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Loader width={'30px'} height={'30px'} color={'#fff'} size={'4'} />
          ) : (
            'Save Prescription'
          )}
        </Button>
      </Actions>
    </div>
  )
}

export default CreatePrescription

import { BodySmall, SubHeading } from '../../base/typography'
import { TableContainer, TableHead, SkeletonArea, EmptyList } from './style'
import { isElement } from '../../../helpers'
import Skeleton from 'react-loading-skeleton'
import Pagination from '../Pagination'
import { Link } from '../Pagination'

const Table = ({
  columns,
  loading,
  dataSource,
  label,
  paginationData,
  handlePagination,
  currentPage,
  handleAction,
  emptyListMessage = 'No Record found',
}: {
  columns: Record<string, any>[]
  loading: boolean
  dataSource: Record<string, any>[]
  label: string
  paginationData?: Array<Link>
  handlePagination?: (pageNumber: number) => void
  currentPage?: number
  handleAction?: (sessionDetails: any) => void
  emptyListMessage?: string
}) => {
  return (
    <TableContainer loading={loading}>
      <SubHeading mt={0} mb={28}>
        {label}
      </SubHeading>
      <table>
        <thead>
          <tr data-testid="table-head-tr">
            {columns && columns.length > 0 ? (
              columns.map((column) => (
                <TableHead data-testid="table-head-th" key={column.key}>
                  <div>{column.title}</div>
                </TableHead>
              ))
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody data-testid="table-body">
          {dataSource?.length > 0 ? (
            dataSource.map((data) => (
              <tr
                data-testid="table-body-tr"
                key={data.key}
                ref={data?.ref}
                onClick={() => {
                  if (handleAction) {
                    handleAction(data.view.props.appointment)
                  }
                }}
              >
                {columns && columns.length > 0 ? (
                  columns.map((column, index) => {
                    return isElement(data[column.dataIndex]) ? (
                      <td key={index}>{data[column.dataIndex]}</td>
                    ) : (
                      <td key={index}>
                        {/*if you are not returning an element*/}
                        <div>{data[column.dataIndex]}</div>
                      </td>
                    )
                  })
                ) : (
                  <></>
                )}
              </tr>
            ))
          ) : (
            <>
              {loading && (
                <SkeletonArea>
                  {columns.map((column) => {
                    return (
                      <td key={column.dataIndex}>
                        <Skeleton width={'60%'} />
                      </td>
                    )
                  })}
                </SkeletonArea>
              )}
            </>
          )}
        </tbody>
      </table>
      {dataSource?.length === 0 && !loading && (
        <EmptyList>
          <BodySmall color={'#7E848F'}>{emptyListMessage}</BodySmall>
        </EmptyList>
      )}
      {paginationData && (
        <Pagination
          links={paginationData}
          handleGoToPage={handlePagination!}
          currentPage={currentPage!}
        />
      )}
    </TableContainer>
  )
}

export default Table

import styled from 'styled-components'
import { toRem } from '../../../../helpers'
import { ScrollBar } from 'styles/global'

export const TextAreaHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${toRem(20)} 0;
  width: 100%;

  label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #344054;
  }
  textarea {
    font-family: 'Plus Jakarta Sans';
    outline: none;
    padding: ${toRem(12)} ${toRem(14)};
    width: 100%;
    margin: ${toRem(6)} 0 0 0;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: ${(props) => props.theme.borderRadius.md};
    resize: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 28px; */
    height: 150px;
    /* identical to box height, or 175% */

    letter-spacing: -0.02em;

    /* Gray/800 */

    color: #1d2939;
    ${ScrollBar}
  }
  textarea::placeholder {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.02em;

    /* Gray/400 */

    color: #98a2b3;
  }
`

import { DefaultTheme } from 'styled-components'

const defaultTheme: DefaultTheme = {
  fontSize: {
    h1: { fontSize: '', lineHeight: '' },
    h2: { fontSize: '', lineHeight: '' },
    h3: { fontSize: '24', lineHeight: '' },
    h4: { fontSize: '', lineHeight: '' },
    h5: { fontSize: '', lineHeight: '' },
    h6: { fontSize: '', lineHeight: '' },
  },
  font: {
    body: 'Plus Jakarta Sans',
    optional: '',
  },
  colors: {
    primary: {
      main: '#1B1D3E',
      dark: '#101828',
      600: '#3E4784',
      500: '#4E5BA6',
    },
    success: {
      100: '#D1FADF',
      200: '#3A9969',
      300: '#57E69E',
      400: '#32D583',
      600: '#039855',

      main: '#1AB265',
      text: '#12B76A',
      light: '#F5FEF9',
    },
    white: '#fff',
    gray: '#475467',
  },
  borderRadius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '0.75rem',
  },
  buttonPalette: {
    secondary: {
      background: '#039855',
      color: '#FFFFFF',
      border: 'none',
    },
    primary: {
      background: '#1B1D3E',
      color: '#FFFFFF',
      border: 'none',
    },
    tertiary: {
      background: '',
      color: '',
      border: 'none',
    },
    outline: {
      background: '#FFFFFF',
      color: '#1B1D3E',
      border: '#BEC2C7',
    },
  },
}

export default defaultTheme

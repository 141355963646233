import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { toRem } from '../../../../helpers'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderRadius.md};
  outline: none;
  border: 1px solid #d0d5dd;
  margin: ${toRem(6)} 0 0 0;
  padding: 5px;
  &:focus-within {
    background-color: '';
    border: '';
  }

  /* Inside Auto Layout */

  @media ${device.tablet} {
    min-width: 100%;
  }

  input {
    border: none;
    width: 100%;
    padding: ${toRem(8)} ${toRem(12)};
    margin: ${toRem(2)};
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.02em;

    /* Gray/800 */

    color: #1d2939;
  }

  input:focus {
    outline: none;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23ff0000" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }

  input::placeholder {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.02em;

    /* Gray/400 */

    color: #98a2b3;
  }

  & > svg {
    margin-right: 0.813rem;
    cursor: pointer;
  }
`

export const InputHolder = styled.div`
  display: flex;
  margin: ${toRem(20)} 0;
  flex-direction: column;
  min-width: 250px;

  label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #344054;
  }

  /* Inside Auto Layout */
  margin: 0.375rem 0;
  @media ${device.tablet} {
    min-width: 100%;
  }
`

export const Info = styled.span`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

export const Error = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Error/500 */

  color: #f04438;
`

import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { ButtonState } from '../Button/style'
import { BodySmallSemiBold } from 'components/base/typography'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export type Link = {
  url: null | string
  label: string | number
  active: boolean
}

export interface PaginationProps {
  links?: Array<Link>
  handleGoToPage: (pageNumber: number) => void
  currentPage: number
}

const Pagination: React.FC<PaginationProps> = ({
  links,
  handleGoToPage,
  currentPage,
}) => {
  let previous
  let next
  let pages

  if (links) {
    previous = links[0]
    next = links[links.length - 1]
    pages = links.filter(
      (link) => link.label !== 'Previous' && link.label !== 'Next'
    )
  }

  const handleAction = (page: number) => {
    handleGoToPage(page)
  }
  return (
    <Container>
      <Actions>
        <Button
          variant={ButtonState.OUTLINE}
          padding={'1px 20px'}
          type={'button'}
          border_radius="7px"
          height="40px"
          disabled={previous?.url === null}
          onClick={() => handleAction(currentPage - 1)}
        >
          <BodySmallSemiBold fontSize={'13px'}>
            {previous?.label}
          </BodySmallSemiBold>
        </Button>
        {pages?.map((link) => (
          <Button
            key={link.label}
            variant={
              currentPage === link.label
                ? ButtonState.PRIMARY
                : ButtonState.OUTLINE
            }
            // padding={'0px 20px'}
            type={'button'}
            height="40px"
            border_radius="50%"
            width="40px"
            onClick={() => handleAction(+link.label)}
          >
            <BodySmallSemiBold fontSize={'13px'}>
              {link.label}
            </BodySmallSemiBold>
          </Button>
        ))}
        <Button
          variant={ButtonState.OUTLINE}
          padding={'0px 10px'}
          type={'button'}
          border_radius="7px"
          height="40px"
          disabled={next?.url === null}
          onClick={() => handleAction(currentPage + 1)}
        >
          <BodySmallSemiBold fontSize={'13px'}>{next?.label}</BodySmallSemiBold>
        </Button>
      </Actions>
    </Container>
  )
}

export default Pagination

import { BodyNormalSemiBold } from 'components/base/typography'
import * as Tabs from '@radix-ui/react-tabs'
import Header from './Header'
import { Wrapper, Heading } from './style'

import About from './About'
import Notes from './Notes'

interface SessionNoteProps {
  name: string
  reason: string
  description: string
  time: string
  image: string
  show: boolean
  appointmentID: number
  type: string
  userID: number
  handleClose: () => void
  handleEndSession: (appointmentID: number) => void
}

const SessionNote: React.FC<SessionNoteProps> = ({
  name,
  reason,
  description,
  time,
  image,
  show,
  appointmentID,
  userID,
  type,
  handleEndSession,
}) => {
  return (
    <Wrapper show={show}>
      <Heading>
        <BodyNormalSemiBold color={'#344054'} data-testid={'name'}>
          Patient Details
        </BodyNormalSemiBold>

        {/* {!sessionStarted && (
          <Icon onClick={() => handleClose()}>
            <Cancel />
          </Icon>
        )} */}
      </Heading>
      <Tabs.Root defaultValue="about" style={{ height: '100%' }}>
        <Header />
        <Tabs.Content value="about" style={{ height: 'calc(100% - 50px)' }}>
          <About
            name={name}
            reason={reason ?? 'No Reason Specified'}
            description={
              description ??
              "No Note Specified, If the client left a note in advance of the session, it'll show up here."
            }
            time={time}
            image={image}
            handleEndSession={() => handleEndSession(appointmentID)}
            type={type + ' Therapy'}
          />
        </Tabs.Content>

        <Tabs.Content value="notes" style={{ height: 'calc(100% - 50px)' }}>
          <Notes userID={userID} appointmentID={appointmentID} />
        </Tabs.Content>
      </Tabs.Root>
    </Wrapper>
  )
}

export default SessionNote

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
// import {} from ‘firebase/database’
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app’s Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCWcY2DVGdI6G0azo_mwYn8oBORBEnS3qQ',
  databaseUrl:
    'https://mytherapistdotng-chat-backend-default-rtdb.firebaseio.com',
  authDomain: 'mytherapistdotng-chat-backend.firebaseapp.com',
  projectId: 'mytherapistdotng-chat-backend',
  storageBucket: 'mytherapistdotng-chat-backend.appspot.com',
  messagingSenderId: '979250952498',
  appId: '1:979250952498:web:6586fc1810038b245b7f2b',
  measurementId: 'G-RXZVFQ5FLX',
}
// Initialize Firebase
initializeApp(firebaseConfig)
export const auth = getAuth()
// export const database = getFirestore();
export const database = getDatabase()

import { useState } from 'react'
import styled from 'styled-components'
import { ScrollBar } from 'styles/global'
import * as Tabs from '@radix-ui/react-tabs'
import { device } from 'helpers/breakpoints'
import { Search } from 'assets/svg'
import { getFilteredResults } from './helpers'
import { BodyNormalBold, BodySmall } from 'components/base/typography'

const Wrapper = styled(Tabs.List)`
  height: 100%;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background: #f5f6ff;
  border-bottom: 2px solid #eaecf0;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${ScrollBar}

  @media ${device.tablet} {
    overflow: auto;
    gap: 40px;
  }
`

const Tab = styled(Tabs.Trigger)`
  cursor: pointer;
  position: relative;
  padding: 16px;
  width: 100%;

  height: fit-content;
  background-color: inherit;
  border: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  gap: 12px;
  text-align: start;

  & p {
    transition: all 0.2s;
  }

  &:hover {
    background-color: #fff;
  }

  &:focus {
    border: 1px solid #eaecf0;
    outline: none;
  }

  &[data-state='active'] {
    box-shadow: 10px -1px 0px -4px #1b1d3e inset;
  }
`
export const InputWrapper = styled.div`
  border: 0.5px solid #d0d5dd;
  height: 56px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  padding: 12px;
  align-items: center;
  width: 90%;
  margin: 14px 0px;
  background-color: #fff;
  gap: 10px;

  & input {
    width: 100%;
    height: 100%;
    border: none;
    font-family: inherit;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #98a2b3;
    }
  }
`

const Avatar = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`

interface HeaderProps {
  data: any
  handleAction: (userID: number) => void
}

const Header: React.FC<HeaderProps> = ({ data, handleAction }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState<any>([])

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
    const filteredData = getFilteredResults(data, e.target.value)

    setSearchResult(filteredData)
  }

  const userObject = searchTerm === '' ? data : searchResult
  return (
    <Wrapper aria-label="Manage your Client notes">
      <InputWrapper>
        <Search />
        <input
          placeholder="Search clients"
          onChange={(e) => handleSearch(e)}
        />
      </InputWrapper>
      {userObject?.map((tab: any) => (
        <Tab value={tab.name} key={tab.id} onClick={() => handleAction(tab.id)}>
          <Avatar>
            <img src={tab.avatar} alt={tab.name} />
          </Avatar>
          <div>
            <BodyNormalBold color={'#333B49'}>{tab.name}</BodyNormalBold>
            <BodySmall color={'#7E848F'}>{tab.notes} notes</BodySmall>
          </div>
        </Tab>
      ))}
    </Wrapper>
  )
}

export default Header

import React, { useState } from 'react'
import NewNoteForm from './utils/NewNoteForm'
import NoteItem from './utils/NoteItem'
import DeleteNote from './utils/DeleteNote'
import UpdateNoteForm from './utils/UpdateNoteForm'
import { NoteStatusType } from './helpers'
import { Note as NoteIcon } from 'assets/svg'
import Buttton from 'components/libs/Button'
import { SubHeadingBold, BodyNormal } from 'components/base/typography'
import { ButtonState } from '../Button/style'
import styled from 'styled-components'
import { useFetchAppointmentNotes } from 'hooks/useNotes'
import { ScrollBar } from 'styles/global'
import { Plus } from 'assets/svg'
import Skeleton from 'react-loading-skeleton'
import { Box } from '@chakra-ui/react'

export const EmptyNotes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
  height: 50%;
`

const Wrapper = styled.div`
  /* height: 650px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 90px);
`

const BtnWrapper = styled.div`
  padding: 21px;
`
export const NoteWrapper = styled.div`
  overflow: auto;
  height: 650px;
  ${ScrollBar}
`

interface NoteProps {
  userID: number
  appointmentID: number
}

const Note: React.FC<NoteProps> = ({ userID, appointmentID }) => {
  const [noteStatus, setNoteStatus] = useState<NoteStatusType>('view')
  const [noteDetails, setNoteDetails] = useState({
    id: 0,
    title: '',
    content: '',
  })
  const { data, isLoading } = useFetchAppointmentNotes(userID)

  const handleNoteStatus = (status: NoteStatusType) => {
    setNoteStatus(status)
  }

  const handleNoteDetails = (
    status: NoteStatusType,
    noteId: number,
    noteTitle: string,
    content: string
  ) => {
    setNoteDetails({ id: noteId, title: noteTitle, content: content })

    handleNoteStatus(status)
  }

  return (
    <Wrapper>
      {isLoading && (
        <Box mt={10} p={5}>
          <Skeleton />

          <Skeleton height="50px" />
        </Box>
      )}

      {data?.length === 0 && noteStatus === 'view' && (
        <EmptyNotes>
          <NoteIcon />
          <SubHeadingBold color={'#333B49'} marginTop={'23px'}>
            Notes is empty
          </SubHeadingBold>
          <BodyNormal color="#7E848F">
            Click the button below to add a note
          </BodyNormal>
        </EmptyNotes>
      )}

      {noteStatus === 'new' && (
        <NewNoteForm
          userID={userID}
          appointmentID={appointmentID}
          handleCancel={handleNoteStatus}
        />
      )}

      {noteStatus === 'delete' && (
        <DeleteNote
          noteName={noteDetails.title}
          noteID={noteDetails.id}
          handleCancel={handleNoteStatus}
        />
      )}

      {noteStatus === 'edit' && (
        <UpdateNoteForm
          title={noteDetails.title}
          noteID={noteDetails.id}
          content={noteDetails.content}
          handleCancel={handleNoteStatus}
        />
      )}

      <NoteWrapper>
        {data?.length !== 0 && (
          <>
            {data?.map((note: any) => {
              return (
                <NoteItem
                  title={note.title}
                  content={note.content}
                  noteID={note.id}
                  key={note.id}
                  time={note.updated_at}
                  handleAction={handleNoteDetails}
                  activeSession={true}
                />
              )
            })}
          </>
        )}
      </NoteWrapper>

      <div>
        <BtnWrapper>
          <Buttton
            variant={ButtonState.PRIMARY}
            padding={'16px'}
            width={'100%'}
            border_radius={'8px'}
            type="button"
            icon={<Plus width={'21px'} />}
            onClick={() => {
              handleNoteStatus('new')
            }}
          >
            Add New Note
          </Buttton>
        </BtnWrapper>
      </div>
    </Wrapper>
  )
}

export default Note

import useAcceptAppointment from 'hooks/appointments/useAcceptAppointment'
import Loader from 'components/libs/Loader'
import {
  BodyNormalSemiBold,
  BodySmall,
  BodySmallSemiBold,
  Paragraph,
} from 'components/base/typography'
import styled from 'styled-components'
import { Decline, Accept, Image } from 'components/libs/Requests/Item'
import moment from 'moment'

interface Props {
  id: number
  closeModal: () => void
  note: string
  image: string
  name: string
  reason: string
  openRejectModal: () => void
  date: string
}

const Flex = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 30px;
`

const Profile = styled.div`
  display: flex;
  flex-direction: column;
`

const Note = styled.div`
  text-align: left;
  margin-bottom: 40px;
  color: #667085;
`
const NoteBody = styled.div`
  background-color: #e4e6e730;
  color: black;
  border-radius: 8px;
  padding: 10px;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-right: auto;
  margin-top: 60px;
`

const Date = styled.div`
  color: #344054;
  margin-right: auto;
`

const AcceptNote: React.FC<Props> = ({
  id,
  closeModal,
  note,
  image,
  name,
  reason,
  date,
  openRejectModal,
}) => {
  const { mutate, isLoading } = useAcceptAppointment()

  const handleAcceptAppointment = () => {
    mutate(id)
    closeModal()
  }

  const handleRejectAppointment = () => {
    closeModal()
    openRejectModal()
  }

  return (
    <div data-testId={'therapist-accept-appointment'}>
      <Flex>
        <Image>
          <img src={image} loading="lazy" alt="person" />
        </Image>
        <Profile>
          <BodySmallSemiBold fontWeight={600} color={'#344054'}>
            {name}
          </BodySmallSemiBold>

          <Paragraph color={'#667085'}>
            <b>Reason:</b> {reason ?? 'No Reason Specified'}
          </Paragraph>
        </Profile>
      </Flex>

      <Note>
        <BodyNormalSemiBold>Booking Note:</BodyNormalSemiBold>
        <NoteBody>
          <BodySmall>
            {note ??
              "No Note Specified, If the client left a note in advance of the session, it'll show up here."}
          </BodySmall>
        </NoteBody>
      </Note>

      <Action>
        {isLoading ? (
          <Loader color={'#1ab265'} width={'30px'} height={'30px'} size={'3'} />
        ) : (
          <>
            <Date>
              <Paragraph>
                <b>Appointment Date:</b> {moment(date).format('ddd D MMM Y')}
              </Paragraph>
              <Paragraph>
                <b>Time:</b> {moment(date).format('hh:mm a')}
              </Paragraph>
            </Date>

            <Decline type="button" onClick={handleRejectAppointment}>
              Decline
            </Decline>
            <Accept type="button" onClick={handleAcceptAppointment}>
              Accept
            </Accept>
          </>
        )}
      </Action>
    </div>
  )
}

export default AcceptNote

import { useQuery } from '@tanstack/react-query'
import { appointmentService } from 'services'

const fetcher = async ({ queryKey }: Record<string, any>) => {
  const response = await appointmentService.getAppointments(queryKey[1])
  return response
}

const useAppointments = (page: number) => {
  return useQuery(['appointments', page], fetcher)
}

export default useAppointments

import { device } from 'helpers/breakpoints'
import * as Tabs from '@radix-ui/react-tabs'
import styled from 'styled-components'
import Header from './Header'
import Content from './Content'
import { ScrollBar } from 'styles/global'

const Wrapper = styled(Tabs.Root)`
  height: max-content;
  border-radius: 8px;
  background: transparent;
  width: 100%;
  margin: 0px auto 0 auto;
  overflow: auto;
  ${ScrollBar};

  @media ${device.tablet} {
    margin: 0;
    max-width: 100%;
  }
`

interface WidgetProps {
  defaultValue: 'overview' | 'appointments' | 'notes' | 'tasks'
}

const ClientTabWidget: React.FC<WidgetProps> = ({ defaultValue }) => {
  return (
    <Wrapper defaultValue={defaultValue}>
      <Header />
      <Content />
    </Wrapper>
  )
}

export default ClientTabWidget

import { device } from 'helpers/breakpoints'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BodySmall } from 'components/base/typography'

export const Wrapper = styled.div`
  flex: 1;
  height: max-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  width: 100%;
  min-width: fit-content;

  @media ${device.mobileL} {
    margin-right: 0;
  }
`

export const Container = styled.div``

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconBox = styled.span`
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoaderWrapper = styled.div`
  text-align: center;
  width: 100%;
`

export const Card = styled.div`
  border-radius: 12px;
  background-color: #fff;
  color: #1b1d3e;
  padding: 19px 21px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  height: max-content;
  border: 1px solid #e4e7ec;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 16px;
  gap: 15px;

  @media ${device.tablet} {
    grid-template-columns: 100%;
  }
`

// ACCOUNT

export const AccountWrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  color: #5d6673;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  margin-top: 20px;
  border: 1px solid #e4e7ec;

  @media ${device.mobileL} {
    grid-template-columns: 1fr;
  }
`

export const AccountHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
  padding: 19px 21px;
  grid-column: 1 / span end;
  flex-wrap: wrap;
  gap: 10px;

  @media ${device.mobileL} {
    grid-column: unset;
  }
`
export const AccountUpdateLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const AccountProfile = styled.div`
  padding: 19px 21px;
  border-right: 1px solid #e4e7ec;
  /* height: 300px; */
`

export const AccountStats = styled.div`
  padding: 19px 21px;
  grid-column: 2 / span end;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: auto;
  row-gap: 20px;
  align-content: baseline;

  @media ${device.mobileL} {
    grid-column: unset;
    row-gap: 30px;

    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    column-gap: 40px;
    justify-content: space-between;
  }
`

export const AccountAvatar = styled.div`
  width: 130px;
  height: 120px;
  /* background-color: green; */
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
export const StatsBox = styled.span`
  ${BodySmall} {
    margin-bottom: 5px;
  }
`

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`

interface StatusProps {
  status: string
  is_prime: string
}

interface RatingProps {
  rating: number
}

export const Status = styled.span<StatusProps>`
  display: flex;
  padding: 10px 18px;
  width: max-content;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  line-height: 0;
  background: ${(props) =>
    props.is_prime
      ? '#FFD700'
      : props.status === 'ACTIVE' || props.status === 'APPROVED'
      ? '#ECFDF3'
      : props.status === 'PENDING' || props.status === 'AWAY'
      ? '#FFEDDD'
      : props.status === 'DISABLED' || props.status === 'REJECTED'
      ? '#FFD6D7'
      : 'red'};

  color: ${(props) =>
    props.is_prime
      ? 'white'
      : props.status === 'ACTIVE' || props.status === 'APPROVED'
      ? '#1AB265'
      : props.status === 'PENDING' || props.status === 'AWAY'
      ? '#FE810E'
      : props.status === 'DISABLED' || props.status === 'REJECTED'
      ? 'red'
      : 'red'};
`

export const AcceptanceRating = styled.span<RatingProps>`
  color: ${(props) =>
    props.rating <= 30
      ? 'red'
      : props.rating <= 60
      ? '#FE810E'
      : props.rating <= 100 || props.rating >= 100
      ? '#1AB265'
      : 'red'};
`

export const PercentageStats = styled(AcceptanceRating)`
  padding: 0 4px;
  width: fit-content;
  height: 15px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 10px;

  background-color: ${(props) =>
    props.rating <= 30
      ? '#FFD6D7'
      : props.rating <= 60
      ? '#FFEDDD'
      : props.rating <= 100 || props.rating >= 100
      ? '#ECFDF3'
      : 'red'};

  & svg path {
    width: 20px;
    height: 20px;
    stroke: ${(props) =>
      props.rating <= 30
        ? 'red'
        : props.rating <= 60
        ? '#FE810E'
        : props.rating <= 100 || props.rating >= 100
        ? '#1AB265'
        : 'red'};

    stroke-width: 0.5px;
  }
`

export const PercentageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const ResponseTime = styled.span<RatingProps>`
  color: ${(props) =>
    props.rating < 2
      ? '#1AB265'
      : props.rating <= 5
      ? '#FE810E'
      : props.rating > 5
      ? 'red'
      : 'red'};
`

export const AverageRating = styled.span`
  display: flex;
  padding: 10px 16px;
  width: max-content;
  justify-content: space-between;
  align-items: center;
  border-radius: 27px;
  text-transform: lowercase;
  line-height: 0;
  background-color: #ecfdf3;
  color: #1ab265;
`

export const PorfolioLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1ab265;
  margin-left: 20px;
`
export const AccountLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 30;
`

import React from 'react'
import { NoteStatusType } from '../helpers'
import FormInput from 'components/libs/Input/FormInput'
import TextArea from 'components/libs/Input/TextArea'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Check, Cancel } from 'assets/svg'
import UnstyledButton from 'components/libs/UnstyledButton'
import { useAddNewNotes } from 'hooks/useNotes'

const Wrapper = styled.div`
  background-color: #f5f6ff;
  padding: 16px 20px;
`

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18.56px;
`

interface FormProps {
  userID: number
  appointmentID: number
  handleCancel: (status: NoteStatusType) => void
}
const NewNoteForm: React.FC<FormProps> = ({
  userID,
  appointmentID,
  handleCancel,
}) => {
  const { mutate } = useAddNewNotes()

  const schema = Yup.object({
    title: Yup.string().required('Note title is required'),
    content: Yup.string().required('Note content is required'),
  })
  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      const newNote = {
        user_id: userID,
        appointment_id: appointmentID,
        title: values.title,
        content: values.content,
      }

      mutate(newNote)
      handleCancel('view')
      resetForm()
    },
  })
  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit} data-testid={'new-note'}>
        <FormInput
          type={'text'}
          name={'title'}
          id={'title'}
          label={'Title'}
          formik={formik}
          onChange={formik.handleChange}
          value={formik.values.title}
          placeholder={'Note Title'}
        />
        <TextArea
          type={'text'}
          id={'content'}
          name={'content'}
          label={'Content'}
          formik={formik}
          onChange={formik.handleChange}
          maxLength={'250'}
          placeholder={'Write your content here...'}
          value={formik.values.content}
        />

        <BtnWrapper>
          <UnstyledButton type="submit">
            <Check />
          </UnstyledButton>
          <UnstyledButton type="button" onClick={() => handleCancel('view')}>
            <Cancel width={'22px'} height={'22px'} />
          </UnstyledButton>
        </BtnWrapper>
      </form>
    </Wrapper>
  )
}

export default NewNoteForm

import React from 'react'
import styled from 'styled-components'
import { BodySmall, BodyNormalSemiBold } from 'components/base/typography'
import UnstyledButton from 'components/libs/UnstyledButton'
import { Delete, Pencil } from 'assets/svg'
import { NoteStatusType } from '../helpers'

const Wrapper = styled.div`
  padding: 16px 20px;
  border-bottom: 2px solid #eaecf0;

  ${BodySmall} {
    margin-top: 8px;

    &:last-of-type {
      margin-top: 16px;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`
interface NoteItemProps {
  title: string
  content: string
  noteID: number
  activeSession: boolean
  time: string
  handleAction: (
    status: NoteStatusType,
    noteId: number,
    noteTitle: string,
    content: string
  ) => void
}
const NoteItem: React.FC<NoteItemProps> = ({
  title,
  content,
  handleAction,
  time,
  noteID,
  activeSession,
}) => {
  const style = activeSession
    ? {}
    : { border: '1px solid #eaecf0', borderRadius: '8px', marginBottom: '36px' }
  return (
    <Wrapper style={style}>
      <Header>
        <BodyNormalSemiBold color={'#292F3A'}>{title}</BodyNormalSemiBold>
        <BtnWrapper>
          <UnstyledButton
            onClick={() => handleAction('edit', noteID, title, content)}
          >
            <Pencil />
          </UnstyledButton>
          <UnstyledButton
            onClick={() => handleAction('delete', noteID, title, '')}
          >
            <Delete />
          </UnstyledButton>
        </BtnWrapper>
      </Header>
      <BodySmall color="#5D6673">{content}</BodySmall>
      {!activeSession && <BodySmall color={'#8D96A8'}>{time}</BodySmall>}
    </Wrapper>
  )
}

export default NoteItem

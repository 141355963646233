import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { TherapistToast } from 'helpers'
import { fetchDataService, appointmentService } from 'services'
import { errorHandler } from 'helpers'
import {
  AcceptProps,
  NoteProps,
  UpdateNoteProps,
} from 'services/AppointmentService'

// FETCH ALL NOTES
const allNotesfetcher = async () => {
  const response = await fetchDataService.getNotes()

  return response?.notes
}
export const useFetchNotes = () => {
  return useQuery(['notes'], allNotesfetcher)
}

// FETCH NOTE FOR A USER
const fetcher = async ({ queryKey }: any) => {
  const userID = +queryKey[1]

  const response = await fetchDataService.getAppointmentNotes(userID)

  return response?.notes
}

export const useFetchAppointmentNotes = (userID: any) => {
  return useQuery(['appointment-notes', userID], fetcher, {
    enabled: !!userID,
  })
}

// ADD NEW NOTE
const newNotefetcherFunction = async (data: NoteProps) => {
  let response
  try {
    const responseData = await appointmentService.addNewNote(data)
    response = responseData

    TherapistToast.success({ msg: 'Note saved successfully' })
  } catch (e) {
    const message = errorHandler(e)
    response = message
  }

  return response
}

export const useAddNewNotes = () => {
  const queryClient = useQueryClient()

  const handleError = (message: string) => {
    TherapistToast.error({ msg: message })
  }

  const mutate = useMutation(
    (note: NoteProps) => newNotefetcherFunction(note),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['appointment-notes'])
      },
      onError: () => handleError('An Error occurred, try again later.'),
    }
  )

  return mutate
}

// DELETE NOTE
const deleteNotefetcherFunction = async (data: AcceptProps) => {
  let response
  try {
    const responseData = await appointmentService.deleteNote(data)
    response = responseData

    TherapistToast.success({ msg: 'Note Deleted!' })
  } catch (e) {
    const message = errorHandler(e)
    response = message
  }

  return response
}

export const useDeleteNote = () => {
  const queryClient = useQueryClient()

  const handleError = (message: string) => {
    TherapistToast.error({ msg: message })
  }

  const mutate = useMutation(
    (noteId: AcceptProps) => deleteNotefetcherFunction(noteId),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['appointment-notes'])
      },
      onError: () => handleError('An Error occurred, try again later.'),
    }
  )

  return mutate
}

// UPDATE NOTE
const updateNotefetcherFunction = async (data: UpdateNoteProps) => {
  let response
  try {
    const responseData = await appointmentService.updateNote(data)
    response = responseData

    TherapistToast.success({ msg: 'Note Updated!' })
  } catch (e) {
    const message = errorHandler(e)
    response = message
  }

  return response
}

export const useUpdateNote = () => {
  const queryClient = useQueryClient()

  const handleError = (message: string) => {
    TherapistToast.error({ msg: message })
  }

  const mutate = useMutation(
    (data: UpdateNoteProps) => updateNotefetcherFunction(data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['appointment-notes'])
      },
      onError: () => handleError('An Error occurred, try again later.'),
    }
  )

  return mutate
}

import styled from 'styled-components'
import UnstyledButton from 'components/libs/UnstyledButton'
import { device } from 'helpers/breakpoints'
import { TitleThree } from 'components/base/typography'
import { useDispatch } from 'react-redux'
import { Bars } from 'assets/svg'
import { toggleMobileMenu } from 'store/modules/modals/actions'

interface WrapperProps {
  show: boolean
}
const Wrapper = styled.header<WrapperProps>`
  height: 80px;
  position: sticky;
  top: 0;
  /* width: ${(p) => (p.show ? 'calc(100% - 380px)' : '100%')}; */
  width: '100%';
  padding: 15px 28px;
  background: #ffffff;
  border: 1px solid #eaecf0;

  @media ${device.laptop} {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  & button {
    display: none;

    @media ${device.laptop} {
      display: block;
    }
  }
`

interface HeaderProps {
  showDetails: boolean
}
const Header: React.FC<HeaderProps> = ({ showDetails }) => {
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }

  return (
    <Wrapper show={showDetails}>
      <UnstyledButton onClick={handleToggle}>
        <Bars width={24} height={25} color="#667085" />
      </UnstyledButton>

      <TitleThree
        color={'#101828'}
        fontFamily={'Clash Grotesk'}
        data-testid={'header'}
      >
       Session Bookings
      </TitleThree>
    </Wrapper>
  )
}

export default Header

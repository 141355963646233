import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

const fetcher = async ({ queryKey }: Record<string, any>) => {
  const response = await fetchDataService.getClientPrescriptions(queryKey[1])
  return response
}

const useFetchClientPrescriptions = (clientId: number) => {
  return useQuery(['prescriptions', clientId], fetcher)
}

export default useFetchClientPrescriptions

import { Wrapper, Heading, Action, ActionIcon } from './style'
import { SubHeadingBold, BodyNormalSemiBold } from 'components/base/typography'
import { BodySmall } from 'components/base/typography'
import { Calendar, Time, Money, Profile } from 'assets/svg'
import { ChevronRightIcon } from '@radix-ui/react-icons'

const actions = [
  {
    title: 'Manage Appointments',
    description: 'Efficiently organize your appointments.',
    icon: <Calendar />,
    route: '/dashboard/bookings',
  },
  {
    title: 'Update Availability',
    description: 'Customize availability for seamless booking.',
    icon: <Time />,
    route: '/dashboard/account/session-settings',
  },
  {
    title: 'Withdraw Funds',
    description: 'Cha-ching, time to reap the fruits of your labour.',
    icon: <Money />,
    route: '/dashboard/account/withdraw-funds',
  },
  {
    title: 'Manage Profile',
    description: 'Update your personal information.',
    icon: <Profile />,
    route: '/dashboard/account/manage-profile',
  },
]
const QuickActions = () => {
  return (
    <Wrapper>
      <Heading>
        <SubHeadingBold>Quick Actions</SubHeadingBold>
      </Heading>
      {actions.map((action) => (
        <Action to={action.route} key={action.route}>
          <ActionIcon>{action.icon}</ActionIcon>
          <div>
            <BodyNormalSemiBold color="#101928">
              {action.title}
            </BodyNormalSemiBold>
            <BodySmall>{action.description} </BodySmall>
          </div>
          <ChevronRightIcon width="24px" height="24px" />
        </Action>
      ))}
    </Wrapper>
  )
}

export default QuickActions

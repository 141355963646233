import { HttpMethod } from './HttpService'
import QueryService from './QueryService'

export type NoteProps = number

export default class FetchDataService {
  constructor(private queryService: QueryService) {}

  async getUpcomingAppointments() {
    const appointmentResponse = await this.queryService.fireRequest(
      {
        url: 'appointments/upcoming',
        method: HttpMethod.GET,
      },
      true
    )

    return appointmentResponse
  }

  async account() {
    const accountResponse = await this.queryService.fireRequest(
      {
        url: 'account',
        method: HttpMethod.GET,
      },
      true
    )

    return accountResponse
  }

  async walletBalance(current: boolean) {
    const response = await this.queryService.fireRequest(
      {
        url: current ? 'wallet' : 'wallet/report',
        method: HttpMethod.GET,
      },
      true
    )

    return response
  }

  async walletTransactions() {
    const response = await this.queryService.fireRequest(
      {
        url: 'wallet/transactions',
        method: HttpMethod.GET,
      },
      true
    )

    return response
  }

  async sessionReport() {
    const response = await this.queryService.fireRequest(
      {
        url: '/appointments/report',
        method: HttpMethod.GET,
      },
      true
    )

    return response
  }

  async appointmentRequest() {
    const response = await this.queryService.fireRequest(
      {
        url: '/appointments/pending',
        method: HttpMethod.GET,
      },
      true
    )

    return response
  }

  async getAppointmentNotes(data: NoteProps) {
    return await this.queryService.fireRequest(
      {
        url: `notes/${data}`,
        method: HttpMethod.GET,
      },
      true
    )
  }

  async getNotes() {
    return await this.queryService.fireRequest(
      {
        url: `notes`,
        method: HttpMethod.GET,
      },
      true
    )
  }
  async getDashboard() {
    return await this.queryService.fireRequest(
      {
        url: `account/dashboard`,
        method: HttpMethod.GET,
      },
      true
    )
  }

  async getTherapistClient(data: number) {
    return await this.queryService.fireRequest(
      {
        url: `clients/${data}`,
        method: HttpMethod.GET,
      },
      true
    )
  }

  async getClientPrescriptions(clientId: number) {
    const response = await this.queryService.fireRequest(
      {
        url: `/prescriptions/${clientId}`,
        method: HttpMethod.GET,
      },
      true
    )

    return response
  }
}

import { Wrapper } from './style'

import Overview from './Overview'
import Account from './Account'

const Analytics = () => {
  return (
    <Wrapper>
      <Overview />
      <Account />
    </Wrapper>
  )
}

export default Analytics

import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

export const fetcher = () => {
  const response = fetchDataService.walletTransactions()

  return response
}

const useFetchTransactions = () => {
  return useQuery(['wallet-transactions'], fetcher)
}

export default useFetchTransactions

import { useMutation } from '@tanstack/react-query'
import { appointmentService } from 'services'
import { TherapistToast } from 'helpers'
import { CreatePrescriptionProps } from 'services/AppointmentService'
import { errorHandler, successHandler } from 'helpers'
import { HttpError } from 'services/HttpService'

const fetcher = async (data: CreatePrescriptionProps) => {
  let response

  try {
    const responseData = await appointmentService.createPrescription(data)

    response = responseData

    successHandler(response?.message)
  } catch (e: any) {
    errorHandler(e)
    const httpError = e as HttpError
    const statusCode = httpError.getStatusCode()

    response = statusCode
  }

  return response
}

const useCreatePrescription = () => {
  const handleError = () => {
    TherapistToast.error({ msg: 'An Error occured. Try again later' })
  }

  const mutate = useMutation((data: CreatePrescriptionProps) => fetcher(data), {
    onError: () => handleError(),
  })

  return mutate
}

export default useCreatePrescription

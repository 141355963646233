import { getFromStorage, removeFromStorage, saveToStorage } from '../helpers'

export interface AuthDataProps {
  user_id?: string
  access_token?: string
  refresh_token?: string
}

export default class StorageService {
  saveAuthData(authData: AuthDataProps) {
    this.saveItemIfProvided('access_token', authData.access_token)
  }

  private saveItemIfProvided(key: string, value?: string) {
    if (value) {
      saveToStorage(key, value)
    }
  }

  clearAuthData() {
    removeFromStorage('access_token')
  }

  getAuthData(): AuthDataProps {
    return {
      access_token: getFromStorage('access_token'),
    }
  }

  get(key: string): any {
    return getFromStorage(key)
  }

  set(key: string, value: string) {
    this.saveItemIfProvided(key, value)
  }

  // getPushNotificationID(): AuthDataProps {
  //   return {
  //     push_notification_id: getFromStorage('isPushNotificationsEnabled'),
  //   }
  // }

  // savePushNotificationID(authData: AuthDataProps) {
  //   this.saveItemIfProvided(
  //     'push_notification_id',
  //     authData.push_notification_id
  //   )
  // }
}

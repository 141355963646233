import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { accountService } from 'services'
import { TherapistToast } from 'helpers'
import { AwayProps } from 'services/AccountService'
import { errorHandler, successHandler } from 'helpers'

export interface AwayActionType extends AwayProps {
  type: 'set' | 'cancel'
}

const fetcher = async (data: AwayActionType) => {
  let response

  const modifiedData: AwayProps = {
    away_from: data.away_from,
    away_until: data.away_until,
  }
  try {
    let responseData

    if (data.type === 'set') {
      responseData = await accountService.setAwayPeriod(modifiedData)
    } else {
      responseData = await accountService.cancelAwayPeriod()
    }
    const response = responseData

    successHandler(response?.message)
  } catch (e) {
    const message = errorHandler(e)
    response = message
  }

  return response
}

const useUpdateAwayPeriod = () => {
  const queryClient = useQueryClient()
  const handleError = (message: string) => {
    TherapistToast.error({ msg: message })
  }

  const mutate = useMutation(
    (awayPeriodData: AwayActionType) => fetcher(awayPeriodData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
      onError: () => handleError('An Error occurred, try again later.'),
    }
  )

  return mutate
}

export default useUpdateAwayPeriod

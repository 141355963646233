import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

export const fetcher = () => {
  const response = fetchDataService.account()

  return response
}

const useFetchAccount = () => {
  return useQuery(['account'], fetcher)
}

export default useFetchAccount

// import { device } from 'helpers/breakpoints'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ScrollBar } from 'styles/global'

export const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  color: #5d6673;
  margin-top: 20px;
  border: 1px solid #e4e7ec;
  height: 500px;
  overflow: auto;
  ${ScrollBar}
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
  padding: 19px 21px;
`

export const Action = styled(Link)`
  display: flex;
  padding: 25px;
  align-items: center;
  transition: background-color 200ms ease-in-out;

  & svg:not(:first-child) {
    margin-left: auto;
    transition: transform 200ms ease-in-out;
  }

  &:hover svg:not(:first-child) {
    transform: translateX(5px);
  }

  &:hover {
    background-color: #f5f7fa;
  }
`
export const ActionIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f0f2f5;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`

import { useEffect, useState } from 'react'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

interface Props {
  closeModal: () => void
  changeModalWidth: (width: string) => void
  handleSuccess: () => void
}

const CreatePrescription: React.FC<Props> = ({
  closeModal,
  changeModalWidth,
  handleSuccess,
}) => {
  const [formStep, setFormStep] = useState<'one' | 'two'>('one')

  useEffect(
    function formStepDidChange() {
      if (formStep === 'one') {
        changeModalWidth('90%')
      } else {
        changeModalWidth('600px')
      }
    },
    [changeModalWidth, formStep]
  )

  return (
    <div>
      {formStep === 'one' && (
        <StepOne
          closeModal={closeModal}
          handNextStep={(step) => setFormStep(step)}
        />
      )}
      {formStep === 'two' && (
        <StepTwo
          closeModal={closeModal}
          handleSuccess={handleSuccess}
          handNextStep={(step) => setFormStep(step)}
        />
      )}
    </div>
  )
}

export default CreatePrescription

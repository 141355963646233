import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { toRem } from 'helpers/index'
import UnstyledButton from 'components/libs/UnstyledButton'
import { TitleThree } from 'components/base/typography'
import { useDispatch } from 'react-redux'
import { Bars } from 'assets/svg'
import { toggleMobileMenu } from 'store/modules/modals/actions'

const Wrapper = styled.header`
  height: 80px;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 28px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  margin-bottom: 25px;

  @media ${device.tablet} {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${TitleThree} {
      font-size: ${toRem(20)};
    }
  }

  & button {
    display: none;

    @media ${device.tablet} {
      display: block;
    }
  }
`

const Header = () => {
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }
  return (
    <Wrapper>
      <UnstyledButton onClick={handleToggle}>
        <Bars width={24} height={25} color="#667085" />
      </UnstyledButton>
      <TitleThree
        color={'#101828'}
        fontFamily={'Clash Grotesk'}
        data-testid={'header'}
      >
        Transactions History
      </TitleThree>
    </Wrapper>
  )
}

export default Header

import { useQuery } from '@tanstack/react-query'
import { fetchDataService } from 'services'

export const fetcher = (query: Record<string, any>) => {
  const current = query?.queryKey[1]
  const response = fetchDataService.walletBalance(current)

  return response
}

const useFetchBalance = (current: boolean) => {
  return useQuery(['wallet-balance', current], fetcher)
}

export default useFetchBalance

import React, { useState } from 'react'
import FormInput from 'components/libs/Input/FormInput'
import TextArea from 'components/libs/Input/TextArea'
import {
  BodyNormalBold,
  BodySmall,
  Paragraph,
  SubHeadingBold,
  TitleTwo,
} from 'components/base/typography'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import styled from 'styled-components'
import Select from 'components/libs/Input/Select'
import { PlusIcon } from '@radix-ui/react-icons'
import {
  CustomDatePicker,
  DatePickerBox,
  Label,
  Value,
} from 'components/libs/forms/AwayPeriodSettings'
import {
  Calendar,
  Cancel,
  ChevronLeft,
  ChevronRight,
  MedicHistory,
} from 'assets/svg'
import {
  dosageOptions,
  durationOptions,
  frequencyOptions,
  intakeOptions,
} from './utils'
import { useTypedDispatch } from '../../../../store'
import { step1 } from '../../../../store/modules/prescription/actions'
import { CreatePrescriptionProps } from 'services/AppointmentService'
import { useParams } from 'react-router-dom'

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`

const FormContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const MedicHistoryBox = styled.div`
  border: 1px solid #e4e5f9;
  background-color: #f5f6f8;
  padding: 20px;
  display: flex;
  align-items: center;
  min-width: 200px;
  width: 350px;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
`

interface Prescription {
  medicationName: string
  dosage: string
  frequency: string
  duration: string
  intake: string
}
interface Props {
  handNextStep: (step: 'one' | 'two') => void
  closeModal: () => void
}

const StepOne: React.FC<Props> = ({ handNextStep, closeModal }) => {
  const { id: userId } = useParams()

  const dispatch = useTypedDispatch()
  const [away_from, onChangeAwayFrom] = useState<Value>(new Date())
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([
    { medicationName: '', dosage: '', frequency: '', duration: '', intake: '' },
  ])

  const schema = Yup.object().shape({
    prescriptionTitle: Yup.string().required('Prescription title is required'),
    specialInstruction: Yup.string().required(
      'Special instruction is required'
    ),
    medications: Yup.array()
      .of(
        Yup.object().shape({
          medicationName: Yup.string().required('Medication name is required'),
          dosage: Yup.string().required('Dosage is required'),
          frequency: Yup.string().required('Frequency is required'),
          duration: Yup.string().required('Duration is required'),
          intake: Yup.string().required('Intake is required'),
        })
      )
      .required(),
  })

  const formik = useFormik({
    initialValues: {
      prescriptionTitle: '',
      specialInstruction: '',
      medications: prescriptions,
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      handNextStep('two')

      const prescriptionsMedication = values.medications.map((medication) => {
        return {
          ...medication,
          refills: 1,
          medication_name: medication.medicationName,
          instructions: values.specialInstruction,
        }
      })

      const prescriptionObject: CreatePrescriptionProps = {
        title: values.prescriptionTitle,
        user_id: Number(userId),
        medications: prescriptionsMedication,
        notes: values.specialInstruction,
      }
      dispatch(step1(prescriptionObject))

      resetForm()
      setPrescriptions([
        {
          medicationName: '',
          dosage: '',
          frequency: '',
          duration: '',
          intake: '',
        },
      ]) // Reset to one form
    },
  })

  const addPrescription = () => {
    setPrescriptions([
      ...prescriptions,
      {
        medicationName: '',
        dosage: '',
        frequency: '',
        duration: '',
        intake: '',
      },
    ])
    formik.setFieldValue('medications', [
      ...prescriptions,
      {
        medicationName: '',
        dosage: '',
        frequency: '',
        duration: '',
        intake: '',
      },
    ])
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      data-testId={'therapist-decline-appointment'}
    >
      <FormWrapper>
        <Header>
          <TitleTwo>Add Prescription</TitleTwo>
          <Paragraph color={'#667085'} fontWeight={500}>
            Fill in the below details to create a prescription for your client.
          </Paragraph>
        </Header>

        <FormContainer
          style={{ borderBottom: '1px solid #eaecf0', paddingBottom: '20px' }}
        >
          <FormInput
            type={'text'}
            name={'prescriptionTitle'}
            id={'prescriptionTitle'}
            label={'Prescription Title'}
            formik={formik}
            value={formik.values.prescriptionTitle}
            placeholder={'Depression Meds'}
          />
          <DatePickerBox style={{ width: '600px' }}>
            <Label>Date Created</Label>
            <CustomDatePicker
              onChange={onChangeAwayFrom}
              value={away_from}
              calendarIcon={<Calendar width={'15px'} />}
              clearIcon={<Cancel width={'17px'} />}
              nextLabel={<ChevronRight />}
              prevLabel={<ChevronLeft />}
            />
          </DatePickerBox>
          <MedicHistoryBox>
            <MedicHistory />
            <div>
              <BodyNormalBold>Franklin Okolie</BodyNormalBold>
              <BodySmall>View client’s medical history</BodySmall>
            </div>
            <ChevronRight color="#667085" />
          </MedicHistoryBox>
        </FormContainer>

        <FormHeader>
          <SubHeadingBold>Medication Details</SubHeadingBold>
          <Button
            variant={ButtonState.PRIMARY}
            padding={'8px 14px'}
            type="button"
            onClick={addPrescription}
          >
            <PlusIcon color="white" width={30} height={30} />
            Add another Prescription
          </Button>
        </FormHeader>

        {formik.values.medications.map((_, index) => (
          <FormContainer key={index}>
            <FormInput
              type={'text'}
              name={`medications[${index}].medicationName`}
              id={`medicationName-${index}`}
              label={'Medication Name'}
              formik={formik}
              value={formik.values.medications[index].medicationName}
              placeholder={'Ibuprofen 400mg'}
            />
            <Select
              type={'text'}
              name={`medications[${index}].dosage`}
              id={`dosage-${index}`}
              label={'Dosage'}
              formik={formik}
              onChange={formik.handleChange}
              placeholder={'Select dosage'}
              value={formik.values.medications[index].dosage}
              options={dosageOptions}
            />
            <Select
              type={'text'}
              name={`medications[${index}].frequency`}
              id={`frequency-${index}`}
              label={'Frequency'}
              formik={formik}
              onChange={formik.handleChange}
              placeholder={'Select frequency'}
              value={formik.values.medications[index].frequency}
              options={frequencyOptions}
            />
            <Select
              type={'text'}
              name={`medications[${index}].duration`}
              id={`duration-${index}`}
              label={'Duration'}
              formik={formik}
              onChange={formik.handleChange}
              placeholder={'Select duration'}
              value={formik.values.medications[index].duration}
              options={durationOptions}
            />
            <Select
              type={'text'}
              name={`medications[${index}].intake`}
              id={`intake-${index}`}
              label={'Intake'}
              formik={formik}
              onChange={formik.handleChange}
              placeholder={'Select intake'}
              value={formik.values.medications[index].intake}
              options={intakeOptions}
            />
          </FormContainer>
        ))}

        <FormContainer>
          <TextArea
            type={'text'}
            name={'specialInstruction'}
            id={'specialInstruction'}
            label={'Special Instruction'}
            formik={formik}
            value={formik.values.specialInstruction}
            placeholder={'Type here'}
            onChange={formik.handleChange}
          />
        </FormContainer>

        <Actions>
          <Button
            variant={ButtonState.OUTLINE}
            padding={'20px 40px'}
            type={'button'}
            onClick={closeModal}
            border_radius="7px"
          >
            Cancel
          </Button>
          <Button
            variant={ButtonState.PRIMARY}
            padding={'20px 40px'}
            type={'submit'}
            border_radius="7px"
          >
            Proceed
          </Button>
        </Actions>
      </FormWrapper>
    </form>
  )
}

export default StepOne

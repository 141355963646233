import styled from 'styled-components'
import { ScrollBar } from 'styles/global'

interface TableContainerProps {
  loading: boolean
}
export const TableContainer = styled.div<TableContainerProps>`
  height: 330px;
  height: 100%;
  padding: 24px;
  margin-top: 32px;
  border: 1px solid #e4e7ec;
  height: fit-content;

  /* Base/White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #eaecf0;
  border-radius: 8px;
  overflow-x: auto;
  ${ScrollBar}

  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding: 0.18rem 2rem;
    text-align: left;
  }

  thead {
    /* Gray/100 */

    background: #f2f4f7;
    opacity: ${(props) => (props.loading ? 0.5 : 1)};
    /* Gray/200 */

    border: 1px solid #eaecf0;
    border-radius: 8px;
    height: 60px;
    padding: 16px 28px;
  }

  td {
    padding: 2rem;
    text-align: left;
    border-bottom: 1px solid #eaecf0;
  }
  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: #f5f7fa;
      transition: background-color 500ms ease-in-out;
    }
  }
`

export const EmptyList = styled.div`
  text-align: center;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
`

export const TableHead = styled.th`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  letter-spacing: -0.02em;

  /* Gray/600 */

  color: #475467;
`

export const SkeletonArea = styled.tr``

import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { ref, onValue, set, push } from 'firebase/database'
import { database } from 'lib/firebase.config'
import TextInput from 'components/libs/Input/FormInput'
import { useFormik } from 'formik'
import Button from '../Button'
import { Send } from 'assets/svg'
import styled from 'styled-components'
import { ButtonState } from '../Button/style'
import Loader from 'components/libs/Loader'
import { BodyNormal } from 'components/base/typography'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { appointmentService } from 'services'
import { errorHandler } from 'helpers'
import {
  Wrapper,
  TherapistMessage,
  ChatBox,
  ChatInput,
  ChatWrapper,
  ClientMessage,
} from './style'
import VideoChat from './VideoChat'
import VoiceChat from './VoiceChat'
import { parseFirebaseChannelName } from '../AccountTabWidget/helpers'

const LoaderWrapper = styled.div`
  height: 70vh;
`

interface ChatProps {
  callType: 'video' | 'voice' | 'chat'
  userId: string
  chatToken: string
  channelName: string
  appointmentId: number
  handleCallType: (type: 'voice' | 'video' | 'chat') => void
}

const Container = styled.div`
  height: 60vh;
  display: grid;
  place-items: center;
  color: #344054;
`

const NewChat = () => (
  <Container> A first timer, start sending messages.</Container>
)

const SessionChat: React.FC<ChatProps> = ({
  callType,
  userId,
  channelName,
  chatToken,
  handleCallType,
  appointmentId,
}) => {
  const { data: account } = useFetchAccount()
  const [loading, setLoading] = useState(true)

  const fullName = account?.name

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (values.message === '') {
        return
      } else {
        handleSendChat(values.message)

        resetForm()
        // SEND TRIGGER NOTIFICATION TO THE SERVER
        const message = {
          id: appointmentId,
          message: values.message,
        }
        try {
          await appointmentService.sendMessage(message)
        } catch (e) {
          errorHandler(e)
        }
      }
    },
  })

  const [messages, setMessages] = useState<any[]>([])
  const chatRef = ref(database, parseFirebaseChannelName(channelName))
  const messageEl = useRef<any>()

  useEffect(() => {
    const listen = onValue(chatRef, (querySnapshot) => {
      const unserialized = JSON.stringify(querySnapshot)
      const serialized = JSON.parse(unserialized)
      if (serialized) {
        const data = Object.values(serialized)
        setMessages(
          data
            .reverse()
            .map((item: any) => ({
              _id: item._id,
              user: item.user,
              text: item.text,
              createdAt: item.createdAt,
            }))
            .reverse()
        )
      }

      setLoading(false)
    })

    return () => listen()
  }, [account, chatRef])

  useEffect(() => {
    if (messageEl) {
      messageEl?.current.addEventListener('DOMNodeInserted', (event: any) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  const handleSendChat = (message: string) => {
    const newChatRef = push(chatRef)
    set(newChatRef, {
      _id: moment(new Date()).format(),
      createdAt: moment().format(),
      text: message,
      user: {
        name: fullName,
        _id: account?.id,
        avatar: account?.avatar,
      },
    })
  }

  const handleMessageTime = (time: string) => {
    const formattedTime = moment(time).format('hh:mm a')

    return formattedTime
  }

  return (
    <Wrapper>
      <ChatWrapper>
        {loading && (
          <LoaderWrapper>
            <Loader
              width={'200px'}
              height={'200px'}
              color={'#1AB265'}
              size={'1'}
            />
          </LoaderWrapper>
        )}

        {messages.length === 0 && callType === 'chat' && <NewChat />}

        {callType === 'chat' && (
          <>
            <ChatBox ref={messageEl}>
              {messages.map((message) => (
                <>
                  {message.system === true && <p>{message.text}</p>}
                  {message.user.name === account?.name ? (
                    <TherapistMessage key={message.id}>
                      <div>
                        <BodyNormal>{message.text}</BodyNormal>
                        <span>
                          <p
                            style={{
                              fontSize: 10,
                              color: '#98A2B3',
                              marginBottom: 0,
                            }}
                          >
                            {handleMessageTime(message.createdAt)}
                          </p>
                        </span>
                      </div>
                      {/* <span>
                        <BodySmall>
                          {handleMessageTime(message.createdAt)}
                        </BodySmall>
                      </span> */}
                    </TherapistMessage>
                  ) : (
                    <ClientMessage key={message.id}>
                      <div
                        style={{
                          marginBottom: -10,
                        }}
                      >
                        <BodyNormal>{message.text}</BodyNormal>
                        <span>
                          <p
                            style={{
                              fontSize: 10,
                              color: '#98A2B3',
                              marginBottom: 0,
                            }}
                          >
                            -{message.user.name}
                          </p>
                          <p
                            style={{
                              fontSize: 10,
                              color: '#98A2B3',
                              marginBottom: 0,
                            }}
                          >
                            {handleMessageTime(message.createdAt)}
                          </p>
                        </span>
                      </div>
                    </ClientMessage>
                  )}
                </>
              ))}
            </ChatBox>
            <ChatInput onSubmit={formik.handleSubmit}>
              <div>
                <TextInput
                  name="message"
                  id="message"
                  placeholder={'Type a message'}
                  label={''}
                  formik={formik}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
              </div>

              <Button
                variant={ButtonState.SECONDARY}
                type="submit"
                padding={'2px 16px'}
                disabled={formik.values.message === ''}
              >
                Send <Send />
              </Button>
            </ChatInput>
          </>
        )}

        {callType === 'video' && (
          <VideoChat
            token={chatToken}
            channelName={channelName}
            handleEndCall={handleCallType}
          />
        )}

        {callType === 'voice' && (
          <VoiceChat
            token={chatToken}
            channelName={channelName}
            handleEndCall={handleCallType}
          />
        )}
      </ChatWrapper>
    </Wrapper>
  )
}

export default SessionChat

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ScrollBar } from 'styles/global'
import { device } from 'helpers/breakpoints'

export const LoaderWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
`
export const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  color: #5d6673;
  margin-top: 20px;
  border: 1px solid #e4e7ec;
  height: 500px;
  overflow: auto;
  isolation: isolate;
  ${ScrollBar}
`
export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
  padding: 19px 21px;
`

export const ViewHistoryLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const Appointments = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  padding: 25px;
  align-items: center;

  @media ${device.mobileL} {
    gap: 30px;
  }
`

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 24px;

  @media ${device.mobileL} {
    margin-right: 0;
  }
`

export const TimeDateContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  & svg {
    margin-right: 10px;
  }
`

export const Date = styled(TimeDateContainer)`
  margin-right: 20px;
  margin-top: 0;
`
export const Time = styled(TimeDateContainer)`
  margin-top: 0;
`

export const Action = styled.div`
  margin-left: auto;

  @media ${device.mobileL} {
    margin-right: 0;
    margin-left: 0;
    width: 100%;

    & button {
      width: 100%;
    }
  }
`

import { applyMiddleware, combineReducers, createStore, AnyAction } from 'redux'

import thunk, { ThunkDispatch, ThunkAction } from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import suspenseReducer from './modules/suspense/reducers'
import authReducer from './modules/auth/reducers'
import modalsReducer from './modules/modals/reducers'
import prescriptionReducer from './modules/prescription/reducers'
import { AuthStateProps } from './modules/auth/types'
import { ModalStateProps } from './modules/modals/types'
import { PrescriptionProps } from './modules/prescription/types'
import { useDispatch } from 'react-redux'

import storage from 'redux-persist/lib/storage'

import { persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'auth',
  storage,
}
const rootReducer = combineReducers({
  suspense: suspenseReducer,
  auth: persistReducer(persistConfig, authReducer),
  modals: modalsReducer,
  prescription: prescriptionReducer,
})

const composeEnhancers = composeWithDevTools({ trace: false, traceLimit: 25 })

function newStore() {
  return createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(thunk))
  )
}

let store = newStore()

export default function getStore() {
  return store
}

export function getState(): RootState {
  return store.getState()
}

export interface RootState {
  suspense: boolean
  auth: AuthStateProps
  modals: ModalStateProps
  prescription: PrescriptionProps
}

/* Types */
export type AppDispatch = typeof store.dispatch
export type ReduxState = ReturnType<typeof rootReducer>
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>
export const useTypedDispatch = () => useDispatch<TypedDispatch>()

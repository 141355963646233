import styled from 'styled-components'
import UnstyledButton from 'components/libs/UnstyledButton'
import { toRem } from 'helpers/index'
import { device } from 'helpers/breakpoints'
import { Bars, ExternalLink } from 'assets/svg'
import {
  TitleThree,
  BodySmall,
  SubHeadingBold,
  BodySmallSemiBold,
} from 'components/base/typography'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { useDispatch } from 'react-redux'
import { toggleMobileMenu } from 'store/modules/modals/actions'

const Wrapper = styled.header`
  height: 110px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 28px;
  background: #ffffff;
  border: 1px solid #eaecf0;

  ${BodySmall} {
    margin-top: 5px;

    @media (max-width: 820px) {
      display: none;
    }
  }

  @media ${device.laptop} {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${TitleThree} {
      font-size: ${toRem(20)};
    }
  }

  & button {
    display: none;

    @media ${device.laptop} {
      display: block;
    }
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 5px;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1ab265;
`

const Header = () => {
  const { data } = useFetchAccount()
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }

  const portfolioURL = `https://${data?.username}.mytherapist.com.ng`

  return (
    <Wrapper>
      <UnstyledButton onClick={handleToggle}>
        <Bars width={24} height={25} color="#667085" />
      </UnstyledButton>

      <Flex>
        <div>
          <SubHeadingBold data-testid={'header-greeting'}>
            Hey 👋 {data?.name} !
          </SubHeadingBold>
          <BodySmall color={'#475467'}>
            Welcome to your dashboard, where you can help make the world a
            better place ❤️
          </BodySmall>
        </div>
        <Link href={portfolioURL} target="_blank" rel="noreferrer">
          <BodySmallSemiBold
            color={'#1ab265'}
            marginTop={0}
            marginRight={'8px'}
          >
            View your public profile here
          </BodySmallSemiBold>
          <ExternalLink />
        </Link>
      </Flex>
    </Wrapper>
  )
}

export default Header

import React from 'react'
import {
  BodyNormal,
  BodyNormalSemiBold,
  BodySmall,
} from 'components/base/typography'
import styled from 'styled-components'
import { Profile, Details, Image, EndSession } from './style'
import { formatDate } from 'helpers'

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
`
interface AboutProps {
  name: string
  reason: string
  description: string
  time: string
  image: string
  type: string
  handleEndSession: () => void
}

const About: React.FC<AboutProps> = ({
  image,
  name,
  reason,
  description,
  type,
  handleEndSession,
  time,
}) => {
  const handleDateFormat = () => {
    const formattedDate = formatDate(time)

    return {
      date: formattedDate.date,
      time: formattedDate.time,
    }
  }
  return (
    <Wrapper>
      <Profile>
        <Image>
          <img src={image} alt="A man" />
        </Image>
        <div>
          <BodyNormalSemiBold color={'#344054'} data-testid={'name'}>
            {name}
          </BodyNormalSemiBold>
        </div>
      </Profile>

      <div>
        <Details>
          <BodySmall
            color={'#667085'}
            style={{ textTransform: 'capitalize' }}
            data-testid={'reason'}
          >
            Type
          </BodySmall>
          <BodyNormal
            data-testid={'status'}
            style={{ textTransform: 'capitalize' }}
          >
            {type}
          </BodyNormal>
        </Details>
        <Details>
          <BodySmall color={'#667085'} data-testid={'reason'}>
            Reason
          </BodySmall>
          <BodyNormal data-testid={'status'}>{reason}</BodyNormal>
        </Details>
        <Details>
          <BodySmall color={'#667085'} data-testid={'reason'}>
            Description
          </BodySmall>
          <BodyNormal data-testid={'status'}>{description}</BodyNormal>
        </Details>
        <Details>
          <BodySmall color={'#667085'} data-testid={'reason'}>
            Date
          </BodySmall>
          <BodyNormal data-testid={'status'}>
            {handleDateFormat().date}
          </BodyNormal>
        </Details>
        <Details>
          <BodySmall color={'#667085'} data-testid={'reason'}>
            TIME
          </BodySmall>
          <BodyNormal data-testid={'status'}>
            {handleDateFormat().time}
          </BodyNormal>
        </Details>
      </div>

      <EndSession type="button" onClick={handleEndSession}>
        End session
      </EndSession>
    </Wrapper>
  )
}

export default About

import { DashboardLayoutStyles } from './style'
import { FC, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import SideNav from '../libs/SideNav'
import SideModal from 'components/libs/Modal/SideModal'
import { DesktopNav } from './style'
import { storageService } from 'services'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useDispatch } from 'react-redux'
import { toggleMobileMenu } from 'store/modules/modals/actions'

interface DashboardLayoutProps {
  children: ReactElement[] | ReactElement
}

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const { access_token } = storageService.getAuthData()
  const { show_sidebar } = useSelector((state: RootState) => state.modals)
  const dispatch = useDispatch()

  if (!access_token) {
    return <Navigate to={'/login'} />
  }

  const handleToggle = () => {
    dispatch(toggleMobileMenu())
  }
  return (
    <>
      <DashboardLayoutStyles data-testid={'dashboard-layout'}>
        <DesktopNav>
          <SideNav />
        </DesktopNav>

        <div>{children} </div>
      </DashboardLayoutStyles>

      <SideModal
        show={show_sidebar}
        handleAction={handleToggle}
        width={'fit-content'}
        direction="left"
        display="mobile"
      >
        <SideNav />
      </SideModal>
    </>
  )
}

export { DashboardLayout }

import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
`
export const LoaderWrapper = styled.div`
  text-align: center;
  width: 100%;
`

interface StatusProps {
  status: string
}

export const Status = styled.span<StatusProps>`
  display: flex;
  width: max-content;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  border-radius: 27px;
  text-transform: capitalize;
  line-height: 0;
  /* background: ${(props) =>
    props.status === 'ACTIVE' || props.status === 'APPROVED'
      ? '#ECFDF3'
      : props.status === 'PENDING' || props.status === 'AWAY'
      ? '#FFEDDD'
      : props.status === 'DISABLED' || props.status === 'REJECTED'
      ? '#FFD6D7'
      : 'red'}; */

  color: ${(props) =>
    props.status.toUpperCase() === 'ACTIVE' ||
    props.status.toUpperCase() === 'APPROVED'
      ? '#1AB265'
      : props.status.toUpperCase() === 'PENDING' ||
        props.status.toUpperCase() === 'AWAY'
      ? '#FE810E'
      : props.status.toUpperCase() === 'DISABLED' ||
        props.status.toUpperCase() === 'REJECTED'
      ? 'red'
      : 'red'};
`

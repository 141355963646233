interface TabProps {
  name: string
  tabType: 'about' | 'notes'
}

export type NoteStatusType = 'edit' | 'new' | 'delete' | 'view'

export const tabs: TabProps[] = [
  {
    name: 'About',
    tabType: 'about',
  },
  {
    name: 'Notes',
    tabType: 'notes',
  },
]

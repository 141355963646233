import React from 'react'
import Header from './Header'
import NotesView from 'components/libs/Notes'

const Notes = () => {
  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <Header />
      <NotesView />
    </div>
  )
}

export default Notes

import { Heading, Info, Wrapper } from '../SessionSettings/style'
import { Paragraph, TitleThree } from '../../../base/typography'
import Button from '../../Button'
import { ButtonState } from '../../Button/style'
import { Status } from './style'
import DatePicker from 'react-date-picker'

import { useFormik } from 'formik'
import { withdrawSchema } from '../../../../helpers/validations'

import styled from 'styled-components'
import { device } from 'helpers/breakpoints'
import { useEffect, useState } from 'react'
import Loader from 'components/libs/Loader'
import { toRem } from '../../../../helpers'
import { Calendar, Cancel, ChevronLeft, ChevronRight } from 'assets/svg'
import useFetchAccount from 'hooks/account/useFetchAccount'

import 'react-date-picker/dist/DatePicker.css'
import 'styles/calendar.css'
import moment from 'moment'

import useUpdateAwayPeriod, {
  AwayActionType,
} from 'hooks/account/useUpdateAwayPeriod'

type ValuePiece = Date | null

export type Value = ValuePiece | [ValuePiece, ValuePiece]

const Grid = styled.div`
  margin-top: 46px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 60px;
  row-gap: 60px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  & > div {
    margin: 0 0 12px 0;
  }
`

const StatusCard = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 41px 67px;
  gap: 8px;

  width: 261px;
  height: 129px;

  /* Gray/50 */

  background: #f9fafb;
  border-radius: 7px;
`

const InfoBox = styled.div`
  background-color: #fffaeb;
  border-radius: 3px;
  padding: 10px;
  align-self: flex-end;

  & p:first-child {
    margin-bottom: 6px;
  }

  & p:not(:first-child) {
    position: relative;
    margin-left: 10px;
    &::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      background-color: #344054;
      border-radius: 50%;
      left: -10px;
      bottom: 5px;
    }
  }
`

const Action = styled.div`
  border-top: 1px solid #eaecf0;
  padding-top: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;

  & button {
    width: 206px;
  }
`

export const DatePickerBox = styled.div`
  min-width: 300px;
  &:last-of-type {
    margin-top: 30px;
  }
`

export const CustomDatePicker = styled(DatePicker)`
  width: 60%;

  & .react-date-picker__wrapper {
    font-family: 'Plus Jakarta Sans';
    height: 50px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    padding: ${toRem(10)} ${toRem(12)};
    width: 100%;
  }

  & .react-date-picker__calendar {
    min-width: 100%;
  }
`

export const Label = styled.label`
  display: block;
  font-family: 'Plus Jakarta Sans';
  font-size: ${toRem(14)};
  line-height: ${toRem(24)};
  font-weight: 600;
  color: rgb(52, 64, 84);
  margin-bottom: 10px;
`

const AwayPeriodSettings = () => {
  const { data: account } = useFetchAccount()
  const { mutate: updateAwayPeriod, isLoading: isUpdating } =
    useUpdateAwayPeriod()
  const [away_from, onChangeAwayFrom] = useState<Value>()
  const [away_until, onChangeAwayUntil] = useState<Value>()

  useEffect(() => {
    onChangeAwayFrom(account?.away?.from)
    onChangeAwayUntil(account?.away?.until)
  }, [account])

  const formik = useFormik({
    initialValues: {
      away_from: '',
      away_until: '',
    },
    validationSchema: withdrawSchema,
    onSubmit: (values, { resetForm }) => {},
  })

  const handleCancelAwayPeriod = () => {
    const awayPeriodData: AwayActionType = {
      type: 'cancel',
      away_from: moment(+away_from!).format('Y-MM-DD'),
      away_until: moment(+away_until!).format('Y-MM-DD'),
    }

    updateAwayPeriod(awayPeriodData)
  }

  const handleSetAwayPeriod = () => {
    const awayPeriodData: AwayActionType = {
      type: 'set',
      away_from: moment(+away_from!).format('Y-MM-DD'),
      away_until: moment(+away_until!).format('Y-MM-DD'),
    }

    updateAwayPeriod(awayPeriodData)
  }

  const handleCalculateDate = (date: string) => {
    return moment(date).format('DD MMMM YYYY')
  }

  return (
    <Wrapper>
      <Heading>
        <TitleThree color={'#1B1D3E'}>Away Period</TitleThree>
        <Paragraph color={'#98A2B3'}>
          A temporary absence during which therapy sessions are not conducted
          due to the therapist's scheduled leave or break
        </Paragraph>
      </Heading>

      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <Grid>
          <StatusCard>
            <Paragraph color={'#98A2B3'}>Status</Paragraph>

            <Status
              status={
                (account?.away?.currently_away ? 'AWAY' : 'ACTIVE') ?? '___'
              }
            >
              <TitleThree lineHeight={0}>
                {(account?.away?.currently_away ? 'Away' : 'Active') ?? '___'}
              </TitleThree>
            </Status>
          </StatusCard>

          <FormContainer data-testid="therapist-update-bank-form">
            <DatePickerBox>
              <Label>Away From</Label>

              <CustomDatePicker
                onChange={onChangeAwayFrom}
                value={away_from}
                calendarIcon={<Calendar width={'15px'} />}
                clearIcon={<Cancel width={'17px'} />}
                nextLabel={<ChevronRight />}
                prevLabel={<ChevronLeft />}
              />
            </DatePickerBox>

            <DatePickerBox>
              <Label>Away Until</Label>
              <CustomDatePicker
                onChange={onChangeAwayUntil}
                value={away_until}
                calendarIcon={<Calendar width={'15px'} />}
                clearIcon={<Cancel width={'17px'} />}
              />
            </DatePickerBox>

            <Info>
              {account?.away?.currently_away ? (
                <Paragraph
                  color={'#FE810E'}
                >{`You are currently away from ${handleCalculateDate(
                  account?.away?.from
                )} until ${handleCalculateDate(
                  account?.away?.until
                )}`}</Paragraph>
              ) : (
                <Paragraph>You are currently active</Paragraph>
              )}
            </Info>
          </FormContainer>

          <InfoBox>
            <Paragraph>Kindly note</Paragraph>

            <Paragraph
              fontWeight={500}
              fontSize={'10px'}
              lineHeight={'15px'}
              color={'#344054'}
            >
              During your away period you will not be booked for a session.
            </Paragraph>
            <Paragraph
              fontWeight={500}
              fontSize={'10px'}
              lineHeight={'15px'}
              color={'#344054'}
            >
              Your away status will display from the start of your period.
            </Paragraph>
            <Paragraph
              fontWeight={500}
              fontSize={'10px'}
              lineHeight={'15px'}
              color={'#344054'}
            >
              Your away status will be shown to clients.
            </Paragraph>

            <Paragraph
              fontWeight={500}
              fontSize={'10px'}
              lineHeight={'15px'}
              color={'#344054'}
            >
              Your earnings will be paused during this period.
            </Paragraph>
          </InfoBox>
        </Grid>

        <Action>
          <Button
            variant={ButtonState.TERTIARY}
            padding={'14px'}
            type={'button'}
            disabled={!account?.away}
            onClick={handleCancelAwayPeriod}
          >
            {isUpdating && account?.away?.currently_away === true ? (
              <Loader
                width={'30px'}
                height={'30px'}
                color={'#fff'}
                size={'4'}
              />
            ) : (
              'Cancel Away Period'
            )}
          </Button>
          <Button
            variant={ButtonState.PRIMARY}
            padding={'14px'}
            type={'button'}
            disabled={account?.away}
            onClick={handleSetAwayPeriod}
          >
            {isUpdating && account?.away?.currently_away === false ? (
              <Loader
                width={'30px'}
                height={'30px'}
                color={'#1B1D3E'}
                size={'4'}
              />
            ) : (
              'Set Away Period'
            )}
          </Button>
        </Action>
      </form>
    </Wrapper>
  )
}

export default AwayPeriodSettings

import { BodyNormal } from 'components/base/typography'
import { device } from 'helpers/breakpoints'
import styled from 'styled-components'

export const DetailsContainer = styled.div`
  margin-top: 32px;
  padding-right: 15px;
  background-color: #ffff;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  overflow: hidden;
  height: max-content;

  @media ${device.laptopL} {
    width: 50%;
  }

  @media ${device.tablet} {
    padding: 20px 20px;
    width: 100%;
  }

  ${BodyNormal} {
    text-transform: capitalize;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 40% 58%;
  justify-content: space-between;

  @media ${device.laptopL} {
    grid-template-columns: 100%;
  }

  @media ${device.tablet} {
    grid-template-columns: 100%;
  }
`

export const TableWrapper = styled.div`
  @media ${device.tablet} {
    padding: 20px 20px;
  }

  ${BodyNormal} {
    text-transform: capitalize;
  }
`

export const Analytics = styled.div`
  padding: 20px 0px;
  padding-bottom: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 16px;
  gap: 15px;
`

export const DetailItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f7f9fc;
  padding: 20px 30px;
`
export const DetailItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const RateContainer = styled.div`
  display: flex;
`

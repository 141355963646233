import styled from 'styled-components'
import { device } from '../../../../helpers/breakpoints'
import { toRem } from '../../../../helpers'

export const SelectHolder = styled.div`
  display: flex;
  margin: 1.25rem 0;
  flex-direction: column;
  position: relative;
  min-width: 250px;

  label {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: ${toRem(14)};
    line-height: ${toRem(20)};
    /* identical to box height, or 143% */

    color: #344054;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
    padding: ${toRem(12)} ${toRem(14)};
    margin: ${toRem(6)} 0 0 0;
    background: ${(props) => props.theme.colors.uburuWhiteSub};
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    border-radius: ${(props) => props.theme.borderRadius.md};
    outline: none;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.02em;

    /* Gray/800 */

    color: #1d2939;

    &:focus {
      // border: 0.063rem solid ${(props) => props.theme.colors.onActive};
      // outline: ${(props) => props.theme.colors.onActive};
    }
  }

  .arrow {
    top: 3rem;
    right: 0.813rem;
    position: absolute;
  }

  .arrow-sub {
    top: 2.6rem;
    right: 0.813rem;
    position: absolute;
  }

  /* Inside Auto Layout */
  margin: 0.375rem 0;
  @media ${device.tablet} {
    min-width: 100%;
  }
`

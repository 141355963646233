export const CREATE_ACCOUNT = '@therapist/create-account'
export const CREATE_ACCOUNT_SUCCESS = '@therapist/create-account-success'
export const CREATE_ACCOUNT_FAILED = '@therapist/create-account-failed'

export const STEP_ONE = '@therapist/step-one'

export const TOGGLE_PASSWORD = '@therapist/toggle-password'
export const TOGGLE_CONFIRM_PASSWORD = '@therapist/toggle-confirm-password'

export const SIGN_IN = '@therapist/signin'
export const SIGN_IN_SUCCESS = '@therapist/signin-success'
export const SIGN_IN_FAILED = '@therapist/signin-failed'

export interface AuthStateProps {
  loading: boolean
  show_repeat_password: boolean
  show_password: boolean
  error: unknown
  email: string
  password: string
  firstname: string
  lastname: string
  phone: string
  phone_region: string
  bio: string
  role: string
  linked_url: string
  specialized_in: string
  years_of_experience: number
  qualifications: string
  password_confirmation: string
  accept_terms: boolean
}

export const authState: AuthStateProps = {
  loading: false,
  show_repeat_password: false,
  show_password: false,
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  phone: '',
  phone_region: '',
  bio: '',
  role: '',
  linked_url: '',
  specialized_in: '',
  years_of_experience: 0,
  qualifications: '',
  password_confirmation: '',
  accept_terms: false,
  error: {},
}

import { FC } from 'react'
import { InputContainer, InputHolder, Info, Error } from './style'
import { withTheme } from 'hoc/withTheme'

interface FormInputProps {
  name: string
  label: string
  type?: string

  [key: string]: any
}

const FormInput: FC<FormInputProps> = ({
  name,
  label,
  formik,
  type = 'text',
  id,
  info,
  placeholder,
  icon,
  ...props
}) => {
  return (
    <InputHolder data-testid={'therapist-input-holder'}>
      <label htmlFor={id}>{label}</label>
      <InputContainer>
        <input
          data-testid={'therapist-form-input'}
          type={type}
          name={name}
          id={id}
          // value={formik.values[name] || props.value}
          value={props.value}
          placeholder={placeholder}
          onChange={formik.handleChange}
          {...props}
        />
        {icon ? icon : ''}
      </InputContainer>
      {info && <Info>{info}</Info>}
      {formik && formik?.errors[name] && (
        <Error data-testid={`errors-${name}`}>{formik?.errors[name]}</Error>
      )}
    </InputHolder>
  )
}

export default withTheme<FormInputProps>(FormInput)

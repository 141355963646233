import { AuthMain } from './style'
import { FC, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { storageService } from 'services'

interface AuthLayoutProps {
  children: ReactElement[] | ReactElement
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const { access_token } = storageService.getAuthData()

  if (access_token) {
    return <Navigate to={'/dashboard'} />
  }
  return <AuthMain data-testid={'authLayout'}>{children}</AuthMain>
}

export { AuthLayout }

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { CTAButtonText } from '../../base/typography'

interface DropdownMenuProps {
  name: string
  children: any
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({ name, children }) => {
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton
            sx={{
              boxSizing: 'border-box',
              width: '263px',
              height: '56px',
              backgroundColor: 'transparent',
              padding: '16px 32px',
              border: 'none',
            }}
          >
            <AccordionIcon
              color={'#344054'}
              sx={{ marginRight: '12px', fontSize: '20px' }}
            />
            <CTAButtonText color={'#344054'}>{name}</CTAButtonText>
          </AccordionButton>
        </h2>
        <AccordionPanel sx={{ padding: '2px 32px' }}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default DropdownMenu

import React, { useState } from 'react'
import styled from 'styled-components'
import AgoraUIKit from 'agora-react-uikit'
import { TherapistToast } from 'helpers'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

interface VideoProps {
  token: string
  channelName: string
  handleEndCall: (type: 'voice' | 'video' | 'chat') => void
}
const VideoChat: React.FC<VideoProps> = ({
  token,
  channelName,
  handleEndCall,
}) => {
  const [videoCall, setVideoCall] = useState(true)
  const rtcProps = {
    appId: '4d3643b1f564412f8c65ac88e85aaf20',
    channel: channelName,
    videoCall: videoCall,
    token: token, // use null or skip if using app in testing mode
  }
  const callbacks = {
    EndCall: () => {
      handleEndCall('chat')
      TherapistToast.success({ msg: 'Video call Ended' })
      setVideoCall(false)
    },
  }
  return videoCall ? (
    <Wrapper>
      <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks}  />
    </Wrapper>
  ) : (
    <h3 onClick={() => setVideoCall(true)}>Start Call</h3>
  )
}

export default VideoChat

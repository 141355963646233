export const dosageOptions = [
  { text: '1 tablet', value: '1 tablet' },
  { text: '2 tablets', value: '2 tablets' },
  { text: '3 tablets', value: '3 tablets' },
  { text: '4 tablets', value: '4 tablets' },
  { text: '5 tablets', value: '5 tablets' },
]

export const frequencyOptions = [
  { text: 'Once daily', value: 'Once daily' },
  { text: 'Twice daily', value: 'Twice daily' },
  { text: '3 times daily', value: '3 times daily' },
  { text: '4 times daily', value: '4 times daily' },
]

export const durationOptions = [
  { text: '7 days', value: '7 days' },
  { text: '14 days', value: '14 days' },
  { text: '24 days', value: '24 days' },
  { text: '30 days', value: '30 days' },
]

export const intakeOptions = [
  { text: 'Before meal', value: 'Before meal' },
  { text: 'After meal', value: 'After meal' },
  { text: 'With meal', value: 'With meal' },
]

import { lazy } from 'react'

import Bookings from 'pages/bookings'
// import Account from 'pages/account'
import Transactions from 'pages/transactions'
import Dashboard from 'pages/dashboard'
import Session from 'pages/session'
import Notes from 'pages/notes'
import Client from 'pages/client'

const SignUp = lazy(() => import('pages/auth/signUp'))
const SignUpVerification = lazy(() => import('pages/auth/verification'))

const SignUpVerified = lazy(() => import('pages/auth/signUpVerified'))
const Qualification = lazy(() => import('pages/auth/qualification'))

const Social = lazy(() => import('pages/auth/social'))
const Review = lazy(() => import('pages/auth/review'))
const Login = lazy(() => import('pages/auth/login'))
const ForgetPassword = lazy(() => import('pages/auth/forgotPassword'))
const ChangePassword = lazy(() => import('pages/auth/changePassword'))
const ManageProfile = lazy(() => import('pages/account/ManageProfile'))
const SessionSettings = lazy(() => import('pages/account/SessionSettings'))
const WithdrawFunds = lazy(() => import('pages/account/WithdrawFunds'))
const BankSettings = lazy(() => import('pages/account/BankSettings'))
const PasswordSettings = lazy(() => import('pages/account/PasswordSettings'))
const AwayPeriodSettings = lazy(
  () => import('pages/account/AwayPeriodSettings')
)

/* I WILL BE TAKING OFF CODE SPILITING FOR THIS ROUTES NOW, BECAUSE OF THE GLITCH IT CAUSES
  ON THE MOBILE MENU, I WILL ADD BACK WHEN I FIND A SOLUTION 

const Therapy = lazy(() => import('pages/therapy'))
const Account = lazy(() => import('pages/account'))
const Transactions = lazy(() => import('pages/transactions'))

const Dashboard = lazy(() => import('pages/dashboard'))

*/
export {
  SignUp,
  SignUpVerification,
  SignUpVerified,
  Qualification,
  Social,
  Review,
  Login,
  ForgetPassword,
  ChangePassword,
  Dashboard,
  Bookings,
  Client,
  // Account,
  Transactions,
  AwayPeriodSettings,
  Session,
  Notes,
  ManageProfile,
  SessionSettings,
  WithdrawFunds,
  BankSettings,
  PasswordSettings,
}

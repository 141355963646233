import { useQuery } from '@tanstack/react-query'
import { appointmentService } from 'services'

const fetcher = async ({ queryKey }: Record<string, any>) => {
  const response = await appointmentService.getClientsAppointments(
    queryKey[1],
    queryKey[2]
  )
  return response
}

const useFetchClientAppointments = (clientId: number, status: string) => {
  return useQuery(['appointments', clientId, status], fetcher)
}

export default useFetchClientAppointments

import styled from 'styled-components'
import { device } from '../../../helpers/breakpoints'
import { toRem } from '../../../helpers'
import { ScrollBar } from 'styles/global'

export interface SideNavProps {
  displaySideNav: boolean
}

export interface NavItemProps {
  active: boolean
}

export const SideNavStyle = styled.aside<SideNavProps>`
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  ${ScrollBar}
  background: #ffffff;
  @media ${device.tablet} {
    /* display: none; */
    /* position: fixed; */
    width: 100%;
    z-index: 3;
    /* height: 100%; */
  }
`

export const SideNavTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5rem;

  img {
    margin-left: 1.313rem;
    width: 13.438rem;
    margin-top: 2rem;
  }
`

export const NavItem = styled.div<NavItemProps>`
  box-sizing: border-box;
  cursor: pointer;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;

  width: 263px;
  height: 56px;

  /* Gray/100 */

  background: ${(props) => (props.active ? '#f2f4f7' : '')};
  /* my therapist blue */

  border-left: ${(props) => (props.active ? '4px solid #1b1d3e' : '')};
  border-radius: ${(props) => (props.active ? '0px 8px 8px 0px' : '')};

  /* Inside auto layout */

  margin: ${toRem(8)} ${toRem(0)};

  & > svg {
    margin-right: ${toRem(12)};
  }
`

export const IntercomBtn = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  background-color: 'red' !important;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;

  width: 263px;
  height: 56px;

  /* Gray/100 */

  /* Inside auto layout */

  margin: ${toRem(8)} ${toRem(0)};

  & > svg {
    margin-right: ${toRem(12)};
  }
`

export const SideNavCenter = styled.div`
  margin-top: 4rem;

  & a {
    display: flex;
    align-items: center;

    padding: 16px 32px;

    & > svg {
      margin-right: ${toRem(12)};
    }
  }
`

export const SideNavFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 32px;
  gap: 24px;
  margin-top: 200px;

  /* position: absolute; */
  /* bottom: 0; */

  & > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 13px 0 13px 15px;
    & > svg {
      margin-right: ${toRem(15)};
    }
  }

  @media ${device.laptop} {
    bottom: -40px;
  }
`

export const Hr = styled.hr`
  width: 247px;
  height: 1px;

  /* Gray/200 */
  color: #eaecf0;

  /* Inside auto layout */

  // margin: 24px 16px 32px;
`

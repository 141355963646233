import { withTheme } from 'hoc/withTheme'
import { FC } from 'react'
import { Error } from '../FormInput/style'
import { ReactElement } from 'react'
import { SelectHolder } from './style'
import { ArrowDown } from 'assets/svg'

export interface OptionProp {
  value: string
  text: string
}

interface SelectProps {
  id: string
  label: string
  options: Array<OptionProp>
  icon?: ReactElement

  [key: string]: any
}

const Select: FC<SelectProps> = ({
  id,
  label,
  formik,
  name,
  icon,
  placeholder,
  options,
  ...props
}) => {
  return (
    <SelectHolder data-testid={'therapist-select-holder'}>
      {label && <label htmlFor={id}>{label}</label>}
      {icon ? icon : <ArrowDown className="arrow" />}
      <select name={name} id={id} {...props} data-testid="therapist-select">
        <option value={''}>{placeholder}</option>
        {options.map((option, idx) => (
          <option value={option?.value} key={idx}>
            {option.text}
          </option>
        ))}
      </select>
      {formik && formik?.errors[name] && <Error>{formik?.errors[name]}</Error>}
    </SelectHolder>
  )
}

export default withTheme<SelectProps>(Select)
